.tabs-list.cus .ant-tabs-tab {
    width: auto;
    margin-right: 25px;
}

.row-hash {
    display: flex;
    gap: 5px;
    align-items: center;
}

.img-cop {
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
}

.stt-row-success {
    color: #35D53D;
}

.stt-row-fail {
    color: rgb(188, 32, 5)
}

.stt-row-pen {
    color: rgba(226, 149, 6, 0.564);
}

input:focus {
    border: none !important;
}

.affiliate .title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.ant-pagination-mini {
    display: none !important;
}
.mar-top-10 {
    margin-top: 10px;
}