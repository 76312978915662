.box-empty-connect {
    padding: 30px;
    border-radius: 12px;
    width: fit-content;
    margin: 100px auto;

    .box-img {
        img {
            width: 140px;
            display: block;
            margin: 0 auto;
        }
    }

    .title {
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        color: #00BCD4;
        margin-bottom: 20px;
    }
    .desc {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: #FFFFFF99;
        margin-bottom: 20px;
    }
}

.position-relative {
    position: relative;
}