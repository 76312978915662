ul {
    padding: 0;
    margin: 0;

    li {
        list-style-type: none;
    }
}

.section-grow {
    padding: 20px;

    .grow-wrap {
        display: flex;
        flex-wrap: wrap;

        .wrap-left {
            width: calc(8.5 / 12 * 100%);
        }

        .wrap-right {
            width: calc(3.5 / 12 * 100%);
        }
    }
}

.tabs-list {
    position: relative;

    .ant-tabs-ink-bar {
        display: none;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-nav::before {
        border-bottom: 1px solid transparent;
    }

    .ant-tabs-nav-list {
        width: 500px;
    }

    .ant-tabs-tab {
        width: 25%;
    }

    .ant-tabs-nav-more {
        display: none;
    }

    &.res-tabs-p2p {
        margin-top: 20px;

        .ant-tabs-nav-list {
            width: 100%;
        }

        .ant-tabs-tab {
            width: 16%;

            &:nth-child(7) {
                width: 10% !important;
            }

            &:nth-child(6) {
                width: 10% !important;
            }
        }

        .ant-tabs-nav-more {
            display: none;
        }

        .ant-tabs-nav::before {
            border-bottom: 1px solid #f0f0f025;
        }
    }

    .ant-tabs-nav {
        .ant-tabs-tab {
            &:hover {
                .title-tabs {
                    color: #fff;
                }
            }
        }

        .ant-tabs-tab-btn {
            text-align: center;
            margin: 0 auto;

            .title-tabs {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: center;
                color: gray;
                display: flex;
                align-items: center;
                transition: 0.3s ease;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }

    .ant-tabs-tab-active {
        border-bottom: 1px solid #2B79F7;

        .ant-tabs-tab-btn {
            text-align: center;
            margin: 0 auto;

            .title-tabs {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: center;
                color: #2B79F7;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;

                    path {
                        fill: #2B79F7 !important;
                        opacity: 1;
                    }

                    g {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px
}

.w-4 {
    width: calc(4 / 12 * 100%);
    padding: 10px;
}

.w-3 {
    width: calc(3 / 12 * 100%);
    padding: 10px;
}

.ant-item-wrap {
    .guide-item {
        border: 1px solid #3B3F45;
        border-radius: 16px;
        cursor: pointer;
        position: relative;
        height: 100%;
        .name-type {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 8px;

            &.res {
                top: 145px;
            }

            .detail-status {
                font-family: Radio Canada;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                background: #000;
                border-radius: 100px;
                padding: 7px 12px;
                display: flex;
                align-items: center;
                margin-right: 15px;
            }

            .detail-status.open {
                color: #00EFFF;
                border: 1px solid #00EFFF;
            }

            .detail-status.coming {
                color: rgba(43, 121, 247, 1);
                border: 1px solid rgba(43, 121, 247, 1);
            }

            .detail-status.end {
                border: 1px solid #F4CA5C;
                color: #F4CA5C;
            }

            .detail-status.ends {
                border: 1px solid #ef2603;
                color: #ef2603;
            }

            .detail-status.tba {
                border: 1px solid #ffffff62;
                color: #fff;
            }

            .type-status {
                font-family: "Radio Canada", sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;
                color: #fff;
                background: #000;
                border-radius: 99px;
                padding: 5px 12px;
                display: flex;
                align-items: center;
                border: 1px solid #000;
                margin-right: 5px;

                img {
                    width: 16px;
                    display: block;
                    margin-right: 5px;
                }

                &.available {
                    background: #068942;
                }
            }

            .type-process {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;
                color: #00EFFF;
                background: #000;
                border-radius: 99px;
                padding: 5px 12px;
                display: flex;
                align-items: center;
                border: 1px solid #00EFFF;

                &.sold-out {
                    border: 1px solid #F4CA5C;
                    color: #F4CA5C;
                }

                &.res {
                    left: 8px;
                }

                &.type-xxx {
                    border: 1px solid #fff;
                    color: #fff;
                }
                &.type-month {
                    color: #fff;
                    border: 1px solid #2965FF;
                }
            }
        }

        .box-img {
            img {
                width: 100%;
                border-radius: 12px;
                height: 185px;
                display: block;
            }
        }

        .bottom-item {
            padding: 8px;
            margin-top: 10px;

            .time-countdown {
                margin-bottom: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                justify-content: space-between;

                .time-l {
                    display: flex;
                    align-items: center;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.3;
                    text-align: left;
                    color: #fff;

                    img {
                        width: 16px;
                        display: block;
                        margin-right: 5px;
                        margin-top: -3px;
                    }
                }

                .time-r {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.3;
                    letter-spacing: 0.03em;
                    text-align: left;
                    color: #fff;
                    margin-left: 5px;
                }
            }

            .poniter-box {
                background: #2B3342;
                padding: 12px;
                border-radius: 8px;
                margin-top: 15px;
                .raise-profit {
                    margin-top: 0 !important;
                    .box-riase {
                        background: transparent !important;
                        border: 0px solid transparent !important;
                        padding: 0 !important;
                        border-right: 1px solid #585e66!important;
                        border-radius: 0 !important;
                    }
                }
            }

            .list-type-manual {
                margin-top: 10px;
                &.res {
                    margin-top: 15px;
                    .item {
                        justify-content: left; 
                        .item-l {
                            font-family: Radio Canada;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.8px;
                            text-align: left;
                            color: #B7BDC6;
                            display: flex;
                            align-items: center;
                            img {
                                width: 16px;
                                display: block;
                                margin-right: 5px;
                            }
                        }
                        .item-r {
                            font-family: Radio Canada;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16.8px;
                            letter-spacing: 0.01em;
                            text-align: left;
                            color: #fff;
                            margin-left: 10px;
                        }
                    }
                }
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .item-l {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #ffffff72;
                    }

                    .item-r {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16.8px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #fff;
                    }
                }
            }

            .resize-invesment {
                border-top: 1px solid #585e66;
                margin-top: 10px;
                padding-top: 10px;

                .total-inves {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .text-l {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #ffffff72;
                    }

                    .text-r {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16.8px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #fff;
                    }
                }
            }

            .list-type-unit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;

                .left-unit {
                    width: 50%;

                    .txt-top {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff72;
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;

                        img {
                            width: 16px;
                            display: block;
                            margin-right: 5px;
                        }
                    }

                    .txt-bottom {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #fff;
                    }
                }

                .right-unit {
                    width: 50%;

                    .txt-top {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: right;
                        color: #ffffff72;
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        margin-bottom: 5px;

                        img {
                            width: 16px;
                            display: block;
                            margin-right: 5px;
                        }
                    }

                    .txt-bottom {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.01em;
                        text-align: right;
                        color: #fff;
                    }
                }
            }

            .location-row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item {
                    display: flex;
                    align-items: center;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.2;
                    text-align: left;
                    color: #ffffff85;

                    &.res {
                        color: #fff;
                        font-weight: 500;
                    }

                    img {
                        margin-top: -2px;
                        width: 16px;
                        display: block;
                        margin-right: 5px;
                    }
                }
            }

            .title-row {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
                white-space: nowrap;  
                overflow: hidden;       
                text-overflow: ellipsis;  
                width: 100%; 
            }

            .bottom-process {
                margin-top: 10px;

                .ant-progress-text {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: left;
                    color: #00EFFF;
                }

                .ant-progress-inner {
                    background: #FFFFFF1A;
                }

                .ant-progress-bg {
                    background: #00EFFF;
                    height: 6px !important;
                }

                .text-process {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .txt {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #ffffff7f;
                    }

                    .number-process {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.8px;
                        text-align: left;
                        color: #00EFFF;

                    }
                }
            }

            .raise-profit {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 15px;

                .apy-profit {
                    width: 25%;

                    .txt-top {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        letter-spacing: 0.01em;
                        text-align: center;
                        color: #ffffff73;
                    }

                    .txt-bottom {
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.2px;
                        text-align: center;
                        color: #2B79F7;
                        margin-top: 5px;
                        place-items: center;
                    }
                }

                .box-riase {
                    width: 75%;
                    border: 1px solid #3B3F45;
                    background: #3B3F4566;
                    padding: 10px;
                    border-radius: 8px;

                    .txt {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #ffffff85;
                        margin-bottom: 10px;
                    }

                    .number-raise {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            display: block;
                        }

                        .number {
                            font-family: Radio Canada;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 19.2px;
                            text-align: left;
                            color: #fff;
                        }

                        .symbol {
                            font-family: Radio Canada;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 19.2px;
                            text-align: left;
                            color: #ffffff85;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.row-user-info {
    padding-left: 20px;

    .box-user-info {
        height: 100%;
        position: relative;
        padding-top: 10px;

        .title-profile {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            color: #fff;
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            img {
                width: 30px;
                display: block;
                margin-right: 10px;
            }
        }

        .balance-user {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient(260.33deg, #0047FF -13.37%, #00BCD4 109.53%);
            border-radius: 8px;
            padding: 15px 20px;
            margin-bottom: 15px;

            .txt-l {
                font-family: Radio Canada;
                font-size: 15px;
                font-weight: 500;
                text-align: left;
                color: #fff;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    width: 16px;
                    display: block;
                    margin-left: 10px;
                }
            }

            .txt-r {
                font-family: Radio Canada;
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                color: #fff;
            }
        }

        .row-membersip {
            .bar-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .txt-l {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: left;
                    color: #fff;
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        display: block;
                        margin-right: 5px;
                    }
                }

                .txt-r {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    /* line-height: 16.8px; */
                    text-align: left;
                    color: #67CC65;
                    // background: linear-gradient(#000, #000) padding-box, linear-gradient(260.33deg, #67CC65 -13.37%, #00BCD4 109.53%) border-box;
                    border: 1px solid transparent;
                    padding: 5px 10px;
                    border-radius: 6px;
                }
            }
        }

        .box-income {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 15px;

            .item {
                width: 49%;
                background: #3B3F4566;
                border-radius: 8px;
                padding: 10px 16px;

                .top {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14.4px;
                    text-align: center;
                    color: #ffffff65;
                    margin-bottom: 10px;
                }

                .bottom {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 14.4px;
                    text-align: center;
                    color: #fff;

                    span {
                        color: #ffffff65;
                    }
                }
            }
        }

        .list-properties {
            margin-top: 20px;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
                margin-bottom: 15px;
            }

            .item-properties {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #3B3F45;
                padding: 10px;
                border-radius: 12px;
                margin-bottom: 10px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }

                .left-pro {
                    display: flex;
                    align-items: center;

                    .stt {
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16.8px;
                        text-align: left;
                        color: #fff;
                        margin-right: 10px;
                    }

                    .box-img {
                        margin-right: 10px;

                        img {
                            width: 60px;
                            display: block;
                            border-radius: 6px;
                        }
                    }

                    .text {
                        padding-right: 15px;
                        .top {
                            font-family: Radio Canada;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16.8px;
                            text-align: left;
                            color: #fff;
                            margin-bottom: 5px;
                        }

                        .bottom {
                            font-family: Radio Canada;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16.8px;
                            text-align: left;
                            color: #ffffff66;
                        }
                    }
                }

                .right-pro {
                    .number {
                        font-family: Radio Canada;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 16.8px;
                        color: #fff;
                    }
                }
            }
        }
    }

}

.filter-grow {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 999;

    .ant-segmented-item-label {
        padding: 0px 15px;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 500;
        line-height: 35px;
        color: #ffffffa6;
        min-height: 35px;
    }

    .ant-segmented-item-selected {
        background: #3B3F4566;

        .ant-segmented-item-label {
            color: #fff;
        }
    }

    .ant-segmented {
        background: transparent;
    }
}

.collapse-faq {
    padding: 80px 0;

    &.res {
        padding: 0;

        .ant-collapse-item {
            width: 100%;
            border: 0px solid transparent;
            margin-bottom: 10px;
            padding: 15px;
            background: #3B3F4566;
        }

        .ant-collapse-item-active {
            .ant-collapse-header {
                background: #2B79F71A;
                border-radius: 8px 8px 0 0;
                
            }

            .ant-collapse-content {
                background: #2B79F71A;
                border-radius: 0 0 8px 8px;
            }

            .ant-collapse-content-box {
                padding: 5px 16px;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #FFFFFF66;
            }
        }
    }

    .ant-collapse-item-active {
        .ant-collapse-header-text {
            color: #2B79F7;
        }
    }

    .title {
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        color: #00EFFF;
        margin-bottom: 30px;
    }

    .ant-collapse {
        border: 0px solid transparent;
    }

    .ant-collapse-item {
        border: 0px solid transparent;
        margin-bottom: 10px;
        background: #3B3F4533;
        border: 1px solid #3B3F4533;
        padding: 15px;
        border-radius: 16px;
        transition: 0.3s ease;

        &:hover {
            background: #3B3F4566;
        }

        &:last-child {
            border-radius: 16px;
        }
    }

    .ant-collapse-header {
        position: relative;

        .ant-collapse-expand-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            transform: rotate(90deg);
            padding-inline-end: 0px !important;

            svg {
                fill: #fff;
            }
        }
    }

    .ant-collapse-header-text {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: #fff;
    }

    .ant-collapse-content {
        background-color: transparent;
        border-top: 0px solid #d9d9d9;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        text-align: left;
        color: #ffffff8a;
    }

    .ant-collapse-content-box {
        padding: 5px 16px;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        text-align: left;
        color: #FFFFFF66;
    }

    .ant-collapse-item-active {
        background: #3B3F4566;

        .ant-collapse-header {
            border-radius: 8px 8px 0 0;
        }

        .ant-collapse-content {
            background: transparent;
        }

        .ant-collapse-content-box {
            padding: 5px 16px;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            text-align: left;
            color: #FFFFFF66;
        }

        .ant-collapse-expand-icon {
            transform: rotate(180deg);

            svg {
                fill: #2B79F7;
            }
        }
    }
}

.left-details {
    position: relative;

    .name-type-detail {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        top: 10px;

        .year-common {
            width: auto;
            height: 35px;
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #2965FF;
            background: #000000C2;
            border-radius: 8px;
            z-index: 999;
            display: grid;
            place-items: center;
            padding: 0 10px;
            margin-right: 5px;
        }

        .apy-common {
            width: auto;
            height: 35px;
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #FF996D;
            background: #000000C2;
            border-radius: 8px;
            z-index: 999;
            display: grid;
            place-items: center;
            padding: 0 10px;
        }
    }

    .slick-arrow {
        background: #000;
        border-radius: 99px;
        padding: 5px;
        width: 25px;
        height: 25px;
        text-align: center;

        &::after {
            top: 32%;
            left: 35%;
            width: 8px;
            height: 8px;
        }
        &.slick-next {
            &::after {
                top: 32%;
                left: 30%;
                width: 8px;
                height: 8px;
            }
        }
    }

    .slick-dots {
        bottom: 0px;
    }

    .box-img {
        margin-bottom: 20px;

        img {
            width: 100%;
            display: block;
            border-radius: 16px;
            height: 500px;
            object-fit: fill;
        }
    }

    .content-details {
        .title {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            margin-bottom: 20px;
            color: #fff;
        }

        .desc {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            text-align: left;
            color: #ffffff5c;
            margin-bottom: 20px;
        }

        .per-rewait-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border: 1px solid #3B3F45;
            border-radius: 12px;
            padding: 20px;
            margin-bottom: 15px;
            transition: 0.3s ease;

            &:hover {
                border: 1px solid #00EFFF;
                box-shadow: 0px 0px 8px 0px #2B79F7 inset;

                .text-per {
                    color: #00EFFF !important;
                }

                li {
                    color: #00EFFF !important;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .number-per {
                width: 35px;
                height: 35px;
                background: #3B3F45;
                border: 1px solid #3B3F4533;
                font-family: Radio Canada;
                font-size: 18px;
                font-weight: 500;
                border-radius: 8px;
                text-align: center;
                color: #fff;
                display: grid;
                place-items: center;
            }

            .content-per {
                width: calc(100% - 50px);
                text-align: left;
                .time-line-per {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21.6px;
                    text-align: left;
                    color: #ffffff77;
                    margin-bottom: 10px;
                }
                .text-per {
                    font-family: Radio Canada;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 21.6px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 10px;
                    transition: 0.3s ease;
                }

                .list-per {
                    padding-left: 15px;

                    li {
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21.6px;
                        text-align: left;
                        color: #fff;
                        list-style-type: disc;
                        margin-bottom: 10px;
                        transition: 0.3s ease;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .note-per {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.6px;
                    text-align: left;
                    color: #ffffff77;
                    margin-top: 10px;
                }
            }
        }

        .item-view {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border: 1px solid #3B3F45;
            border-radius: 12px;
            padding: 10px;
            margin-bottom: 15px;
            transition: 0.3s ease;
            cursor: pointer;

            &:hover {
                border: 1px solid #00EFFF;
                box-shadow: 0px 0px 8px 0px #2B79F7 inset;
                .title-view {
                    color: #00EFFF !important;
                }
            }

            .title-view {
                font-family: Radio Canada;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                line-height: 2;
                color: #fff;
                transition: 0.3s ease;
            }

            .button-view {
                .btn-view-page {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    color: #fff;
                    padding: 7px 15px;
                    background: #0159F9;
                    border-radius: 8px;

                    a {
                        color: #fff;
                    }

                    &.dis {
                        background: #3B3F45;
                        cursor: no-drop;
                    }
                }
            }
        }
    }

    .list-detail-info {
        &.res-page {
            .item {
                &:nth-child(4) {
                    border-bottom: 0px solid #AEABFF33;
                }

                &:nth-child(5) {
                    border-bottom: 0px solid #AEABFF33;
                }
            }
        }

        .row-info {
            display: flex;
            flex-wrap: wrap;

            .item {
                width: calc(4/12*100%);
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #AEABFF33;

                .row-left {
                    margin-right: 15px;

                    .box-img-info {
                        img {
                            width: 20px;
                        }
                    }
                }

                .row-right {
                    .txt-top {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #ffffff5c;
                        margin-bottom: 10px;
                    }

                    .txt-bottom {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19.36px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.content-project {
    padding-left: 20px;

    .location-page {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .status-detail {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #00EFFF;
            background: #000;
            border-radius: 8px;
            padding: 7px 12px;
            display: flex;
            align-items: center;
            border: 1px solid #00EFFF;
            margin-right: 15px;

            &.sold-out {
                border: 1px solid #F4CA5C;
                color: #F4CA5C;
            }
        }

        .checkin-detail {
            display: flex;
            align-items: center;
            margin-right: 15px;

            img {
                display: block;
                margin-right: 5px;
            }

            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff7a;
        }

        .user-detail {
            display: flex;
            align-items: center;
            margin-right: 15px;

            img {
                display: block;
                width: 16px;
                margin-right: 5px;
            }

            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff7a;
        }
    }

    .name-detail {
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;
        text-align: left;
        color: #fff;
        margin-bottom: 20px;
    }

    .owner-account {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #FFFFFF33;

        .box-img {
            margin-right: 10px;

            img {
                width: 35px;
                height: 35px;
                object-fit: cover;
                display: block;
                border-radius: 99px;
            }
        }

        .name-account {
            .top {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.01em;
                text-align: left;
                color: #ffffff77;
                margin-bottom: 5px;
            }

            .bot {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.01em;
                text-align: left;
                color: #fff;
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    display: block;
                    margin-left: 5px;
                }
            }
        }
    }

    .funding-target {
        margin-bottom: 15px;

        .time-countdown {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .time-l {
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: left;
                color: #ffffff77;

                img {
                    width: 16px;
                    display: block;
                    margin-right: 5px;
                }
            }

            .time-r {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.2px;
                letter-spacing: 0.03em;
                text-align: left;
                color: #4687FF;
            }
        }

        .apy-reward {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .item {
                width: 50%;

                .title {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #ffffff77;
                    margin-bottom: 5px;
                }

                .number-apy-basic {
                    background: #45EAFF;
                    padding: 5px 12px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.4x;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #000;
                    border-radius: 8px;
                    width: fit-content;
                }

                .number-apy-boost {
                    background: #FFC700;
                    padding: 5px 12px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.4x;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #000;
                    border-radius: 8px;
                    width: fit-content;
                }
            }
        }

        .funding-row {
            background: #FFFFFF1A;
            padding: 15px;
            border-radius: 12px;

            .name {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Radio Canada;
                font-size: 15px;
                font-weight: 400;
                line-height: 1.4;
                letter-spacing: 0.01em;
                text-align: left;
                color: #ffffff86;

                .number-target {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.4;
                    letter-spacing: 0.01em;
                    color: #fff;
                    margin-right: auto;
                    margin-left: 5px;
                }

                .number {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.4;
                    letter-spacing: 0.01em;
                    color: #ffffff86;
                }
            }

            .bottom-process {
                margin-top: 10px;

                .ant-progress-text {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1.4;
                    letter-spacing: 0.01em;
                    color: #00EFFF;
                }

                .ant-progress-inner {
                    background: #FFFFFF1A;
                }

                .ant-progress-bg {
                    background: #00EFFF;
                    height: 6px !important;
                }
            }
        }
    }

    .investment-period {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .title {
            font-family: Radio Canada;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: 0.01em;
            text-align: left;
            color: #ffffff86;
        }

        .month {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.4;
            letter-spacing: 0.01em;
            color: #fff;
        }
    }

    .row-purchase {
        border: 1px solid #3B3F45;
        padding: 12px;
        border-radius: 12px;

        .lends-page {
            background: #222528;
            padding: 10px;
            border-radius: 8px;

            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left-lend {
                    .text {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 1.4;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #ffffff86;
                    }
                }

                .right-lend {
                    display: flex;
                    align-items: center;

                    .balance-wallet {
                        display: flex;
                        align-items: center;

                        img {
                            width: 20px;
                            display: block;
                        }

                        .eth-child {
                            width: 16px;
                            display: block;
                        }

                        .number-balance {
                            font-family: Radio Canada;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: left;
                            color: #fff;
                            margin: 0 5px;
                        }
                    }

                    .button-percent {
                        margin-left: 10px;

                        .btn-percent {
                            width: 60px;
                            height: 30px;
                            background: #FFFFFF0F;
                            text-align: center;
                            font-family: Radio Canada;
                            font-size: 14px;
                            font-weight: 600;
                            color: #fff;
                            border: 0px solid transparent;
                            border-radius: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .bottom {
                padding: 10px;
                margin-top: 15px;
                background: #03030366;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .currentcy {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    background: #222528;
                    border-radius: 8px;
                    padding: 15px;
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: left;

                    img {
                        width: 20px;
                        display: block;
                        margin-right: 10px;
                    }
                }

                .value-current {
                    width: 70%;

                    .value-top {
                        margin-bottom: 5px;
                        text-align: right;

                        input {
                            font-family: Radio Canada;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 21.6px;
                            text-align: right;
                            color: #fff;
                            background: transparent;
                            border: 0px solid transparent;
                            margin-left: auto;
                            &::placeholder {
                                text-align: right;
                                font-size: 18px;
                            }
                            &:focus {
                                outline: none;
                                border: 0px solid transparent !important;
                            }
                        }

                    }

                    .value-bot {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21.6px;
                        text-align: right;
                        color: #ffffff7d;
                    }
                }
            }
        }
    }

    .time-sale-process {
        margin-top: 15px;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        color: #ffffff87;
    }
}

.target-sale-box {
    margin-top: 20px;
    border-radius: 8px;
    background: #2B79F71A;
    padding: 15px 10px;

    .top-process {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .txt {
            font-family: Radio Canada;
            font-size: 15px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff82;
        }

        .number {
            font-family: Radio Canada;
            font-size: 15px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: left;
            color: #fff;
        }
    }

    .bottom-process {
        .ant-progress-text {
            display: none;
        }

        .ant-progress-inner {
            background: #FFFFFF1A;
        }

        .ant-progress-bg {
            background: #00EFFF;
            height: 6px !important;
        }
    }
}

.button-row {
    .btn-buy {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: center;
        background: linear-gradient(#000, #000) padding-box,
            radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) border-box;
        border-radius: 50em;
        border: 1px solid transparent;
        padding: 12px 23px;
        color: #fff;
        border-radius: 8px;
        cursor: pointer;
        width: 100%;
    }
}

.ant-skeleton {
    .ant-skeleton-title {
        display: none !important;
    }

    .ant-skeleton-paragraph {
        li {
            width: 100% !important;
            background: #ffffff17 !important;
        }
    }
}

.selekton-ant-row {
    .ant-skeleton-paragraph {
        margin-block-start: 0 !important;
        margin: 10px 0 !important;
    }
}

.spin-loading {
    .ant-spin-dot {
        color: #00EFFF;
    }
}

.no-data {
    padding-top: 100px;
    text-align: center;
    width: 100%;

    &.res {
        padding-top: 50px;
    }
}

.mar-top-20 {
    margin-top: 20px;
}

.ant-select-filter {
    margin-right: 10px;

    &.row {
        .ant-select {
            width: 100%;
        }
    }

    .ant-select:hover .ant-select-selector {
        box-shadow: 0px 2px 6px 0px #0000001A inset;
        border: 1px solid transparent;
    }

    .ant-select {
        width: 100%;
        height: 48px;
        background: #222528;

        border: 1px solid transparent;
        transition: 0.3s ease;
        border-radius: 6px;
        color: #fff;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: 0.01em;

        .ant-select-selector {
            width: 100%;
            height: 48px;
            padding: 0 15px;
            background: transparent;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1019607843) inset;
            border: 1px solid transparent;
            transition: 0.3s ease;
            border-radius: 6px;
            color: #fff;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.01em;

            .text-chain {
                display: flex;
                align-items: center;

                img {
                    display: block;
                }
            }

            .ant-select-selection-item {
                color: #fff;
                font-family: Radio Canada;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }

        .ant-select-arrow {
            color: #fff;
        }
    }
}

.ant-select-dropdown {
    border-radius: 8px;
    border: 1px solid rgba(20, 26, 76, 1);
    background: #000;

    .rc-virtual-list-holder-inner {
        padding: 5px !important;
    }

    .ant-select-item {
        color: #fff;
        font-family: Radio Canada;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        padding: 10px 10px;
        border-radius: 8px !important;
    }

    .ant-select-item-option-content {
        color: #fff;
        font-family: Radio Canada;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            margin-right: 5px;
            display: block;
        }
    }

    .text-chain {
        display: flex;
        align-items: center;
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
        background: rgba(43, 121, 247, 0.1019607843) !important;
    }

    .ant-select-item-option-active {
        background: rgba(43, 121, 247, 0.1019607843) !important;
        border-radius: 8px !important;
    }

    .ant-select-item-option-selected {
        background: transparent !important;
    }
}

.confirm-box {
    .spin-stake {
        .ant-spin {
            color: #fff;
        }
    }
}

.group-button {
    margin-top: 15px;

    &.res-button {
        margin-top: 0;
    }

    .btn-actions {
        font-family: "Radio Canada", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: center;
        background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
        box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
        width: 100%;
        height: 45px;
        border-radius: 8px;
        border: 0px solid transparent;
        color: #fff;
        cursor: pointer;

        &.dis {
            background: #8E8E8E33;
            color: #fff;
            pointer-events: none;
            box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;

            .ant-spin {
                margin-left: 10px;
                color: #fff;
            }
        }
    }
}

.modal-purchase {
    border-radius: 16px;
    &.ress {
        width: 375px !important;
    }

    .ant-modal-content {
        background: transparent !important;
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-modal-close-x {
        svg {
            fill: #fff;
        }
    }

    .ant-modal-header {
        background: #0A1936;
        border-radius: 16px 16px 0 0;
        padding: 20px 0;
        margin-bottom: 0;

        .ant-modal-title {
            font-family: Radio Canada;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #fff;
        }
    }

    .ant-modal-body {
        border-radius: 16px;
        iframe {
            min-width: 100% !important;
        }
    }

    .ant-modal-content {
        padding: 0;
        border-radius: 16px;
        border: 1px solid #2B79F74D;
    }

    .confirm-box {
        background: #000;
        border-radius: 0 0 16px 16px;
        text-align: center;
        padding: 20px;

        .box-hash-modal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;

            .tsx-l {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #fff;
            }

            .tsx-r {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #fff;
                display: flex;
                align-items: center;

                a {
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                }

                img {
                    width: 16px;
                    display: block;
                    margin-left: 5px;
                }
            }
        }

        .box-img {
            margin: 20px 0;

            img {
                width: 64px;
                display: block;
                margin: 0 auto;
            }
        }

        .title {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #2B79F7;
            margin-bottom: 15px;

            &.res-title {
                color: #35D53D;
            }
        }

        .guide-confirm-project {
            background: #2B79F71A;
            padding: 15px;
            border-radius: 12px;

            .name-pr {
                font-family: Radio Canada;
                font-size: 18px;
                font-weight: 500;
                line-height: 21.6px;
                text-align: left;
                color: #fff;
                margin-bottom: 15px;
            }

            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                .txt-l {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #ffffff9b;
                }

                .txt-r {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: left;
                    color: #fff;
                }
            }
        }
    }
}

.history-purchase {
    .resige-history {
        padding: 15px 0;
        margin-top: 15px;
        &.push-his {
            border: 1px solid #3B3F4566;
            border-radius: 8px;
            padding: 15px;
        }
    
    }

    .title-history {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: #fff;
        margin-bottom: 15px;
    }

    .top-pur {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .item:nth-child(1) {
            width: 20%;
        }

        .item:nth-child(2) {
            width: 40%;
        }

        .item:nth-child(3) {
            width: 40%;
        }

        .item {
            text-align: left;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            color: #ffffff7a;
        }
        .res-row {
            width: 25% !important;
            text-align: left;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            color: #ffffff7a;
        }
    }

    .bottom-pur {
        .row-tsx {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .res-row {
                width: 25% !important;
                text-align: left;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                color: #fff;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .item:nth-child(1) {
                width: 20%;
            }

            .item:nth-child(2) {
                width: 40%;
                color: #fff;
            }

            .item:nth-child(3) {
                width: 40%;
                color: #ffffff7a;
            }

            .item {
                text-align: left;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                color: #fff;
            }
        }
    }
}

.wrap-summary {
    background: #222528;
    border-radius: 12px;
    padding: 15px;
    margin-top: 15px;

    .row-summary {
        .title {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .active {
                transform: rotate(-180deg);
            }

            img {
                width: 20px;
                display: block;
            }
        }
    }

    .row-details-summary {
        overflow: hidden;
        height: 100%;

        &.active {
            height: 0;
        }

        .list-sum {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;

                .txt-l {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #ffffff65;
                }

                .txt-r {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #fff;
                }
            }
        }
    }
}

.age-claim-box {
    margin-top: 15px;
    border: 1px solid #3B3F45;
    padding: 15px;
    border-radius: 12px;

    .claim-detail-box {
        background: #030303;
        box-shadow: 0px 0px 8px 0px #2B79F7;
        border-radius: 8px;
        padding: 15px;

        .text {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
        }

        .time-box {
            font-family: Radio Canada;
            font-size: 24px;
            font-weight: 500;
            line-height: 28.8px;
            text-align: center;
        }
    }

    .claimable-box {
        background: #222528;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-box {
            .txt {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #ffffff87;
            }

            .desc {
                margin-top: 15px;
                display: flex;
                align-items: center;

                span {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: left;
                    color: #fff;
                }

                img {
                    width: 16px;
                    display: block;
                    margin: 0 5px;
                }
            }
        }

        .right-box {
            .claim-able-box {
                background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
                box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
                padding: 10px 15px;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.4;
                text-align: center;
                color: #fff;
                border: 0px solid transparent;
                border-radius: 8px;
                cursor: pointer;

                &.dis {
                    background: #8E8E8E;
                    box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
                    padding: 10px 15px;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.4;
                    text-align: center;
                    color: #fff;
                    border: 0px solid transparent;
                    border-radius: 8px;
                }
            }
        }

    }

    .avaiable-box {
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        margin-top: 15px;
    }
}

.box-img-mey-black {
    border: 1px dashed #3B3F45;
    height: 100%;
    border-radius: 16px;
    display: grid;
    place-items: center;
    text-align: center;

    img {
        width: 142px;
        display: block;
        margin: 0 auto;
    }
}

.different-connect {
    margin: 15px 0;

    .button-different {
        background: #0159F9;
        box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
        padding: 12px 0;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: center;
        width: 100%;
        border: 0px solid transparent;
        color: #fff;
        border-radius: 8px;
        cursor: pointer;
    }
}

.ant-modal-mask {
    background-color: rgb(0 0 0 / 60%) !important;
}

.box-no-data-lending {
    width: 100%;
    text-align: center;
    padding: 30px 0;

    img {
        width: 120px;
        display: block;
        margin: 0 auto;
    }
}

.no-data-page {
    width: 100%;
    text-align: center;
    padding: 20px 0;

    img {
        width: 100px;
        display: block;
        margin: 0 auto;
    }
}

.warning-pool {
    background: #FFC700;
    border: 1px solid #FFC7000A;
    padding: 10px;
    text-align: center;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 8px;
    margin-bottom: 10px;

    img {
        width: 16px;
        display: block;
        margin-right: 5px;
    }
}

.mar-b-100-p2p {
    margin-bottom: 100px;
}

.grow-chart-market {

    .title {
        font-family: Radio Canada;
        font-size: 18px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        color: #fff;
        margin-bottom: 15px;
    }
    .chart-market-box {
        border: 1px solid #3B3F45;
        padding: 20px;
        border-radius: 8px;
        background: url('../../../../public/images/bg-chart.png');
        background-size: cover;
        background-position: top;
        canvas {
            height: 450px !important;
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .w-4 {
        width: 50%;
    }

    .main-header-app .left-app {
        width: 50%;
    }

    .main-header-app .right-app {
        width: 50%;
    }

    .main-header-app .right-app .row-item .input-search {
        display: none;
    }

    .main-header-app .right-app .row-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .section-grow .grow-wrap .wrap-left {
        width: 65%;
    }

    .section-grow .grow-wrap .wrap-right {
        width: 35%;
    }

    .tabs-list .ant-tabs-nav .ant-tabs-tab-btn .title-tabs {
        font-size: 14px;
    }

    .tabs-list .ant-tabs-tab-active .ant-tabs-tab-btn .title-tabs {
        font-size: 14px;
    }
    .tabs-list .ant-tabs-nav-list {
        width: auto;
    }
    .tabs-list .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 15px;
    }
    .tabs-list .ant-tabs-tab {
        width: auto;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .w-4 {
        width: 100%;
    }

    .section-grow .grow-wrap .wrap-left {
        width: 65%;
    }

    .content-project .funding-target .funding-row .name {
        font-size: 12px;
    }

    .content-project .funding-target .funding-row .name .number {
        font-size: 12px;
    }

    .section-grow .grow-wrap .wrap-right {
        width: 35%;
    }

    .main-header-app .left-app {
        width: 50%;
    }

    .main-header-app .right-app {
        width: 50%;
    }

    .main-header-app .right-app .row-item .input-search {
        display: none;
    }

    .main-header-app .right-app .row-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .tabs-list .ant-tabs-tab-active .ant-tabs-tab-btn .title-tabs {
        font-size: 12px;
    }

    .tabs-list .ant-tabs-nav .ant-tabs-tab-btn .title-tabs {
        font-size: 12px;
    }

    .tabs-list .ant-tabs-nav-list {
        width: 240px;
    }

    .filter-grow .ant-segmented-item-label {
        font-size: 12px;
        padding: 0px 10px;
    }
    .left-details .content-details .title {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .left-details .content-details .desc {
        font-size: 12px;
    }
}

.wrap-left.his {
    width: 100% !important;
    // height: calc(100vh - 400px);
}

.his .list-filter-profile .row-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.his .group-his-left {
    display: flex;
    gap: 10px;
    align-items: center;
}

.map-api-box {
    iframe {
        width: 100% !important;
        border-radius: 8px;
    }
}

.comming-soon-text {
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #00EFFF;
    margin-bottom: 30px;
    margin-top: 30px;
}

.number-claim-amount {
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
    padding: 10px 0;
}
.res-span {
    color: #45EAFF !important;
}
.mar-l-10 {
    margin-left: 10px;
}
.section-grow  {
    .title-p2p-mobile {
        font-family: Radio Canada;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;    
        margin-bottom: 10px;     
    }
    .grap-mobile-p2p {
        flex-direction: column-reverse;
        .wrap-right {
            width: 100%;
            .row-user-info {
                padding-left: 0;
                background: #FFFFFF1A;
                padding: 10px;
                border-radius: 12px;
                .box-user-info {
                    padding-top: 0;
                    .box-income {
                        .bottom {
                            font-size: 14px;
                        }
                    }
                    .list-properties {
                        .title {
                            font-size: 14px;
                        }
                        .item-properties {
                            .text {
                                .top {
                                    font-size: 14px;
                                }
                            }
                            .right-pro {
                                .number {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .wrap-left {
            width: 100%;
            .content-grow {
                margin-top: 20px;
                .filter-grow {
                    left: 0;
                    right: inherit;
                    top: 60px;
                }
                .ant-tabs-mobile {
                    .ant-tabs-content {
                        padding-top: 50px;
                    }
                    .ant-tabs-tab {
                        margin-right: 20px;
                        .title-tabs {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.section-grow {
    &.grap-mobile {
        .ant-col-12 {
            flex: 0 0 100%;
            max-width: 100%;
            .ant-collapse-item {
                padding: 0;
            }
        }
        .grap-mobile-p2p-detail {
            flex-direction: column-reverse;
            .wrap-right {
                width: 100%;
                .content-project {
                    padding-left: 0;
                    .location-page {
                        margin-bottom: 10px;
                    }
                    .name-detail {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    .owner-account {
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }
                    .funding-target {
                        .warning-pool {
                            font-size: 12px;
                        }
                        .time-countdown {
                            margin-bottom: 10px;
                        }
                    }
                    .funding-row {
                        .name {
                            font-size: 14px;
                            .number {
                                font-size: 14px;
                            }
                        }
                        .bottom-process {
                            margin-top: 5px;
                        }
                    }
                    .group-button {
                        .btn-actions {
                            font-size: 14px;
                        }
                    }
                    .history-purchase {
                        .resige-history {
                            padding: 10px 0;
                            margin-top: 10px;
                        } 
                    }
                    .age-claim-box {
                        margin-top: 10px;
                        margin-bottom: 20px;
                        padding: 10px;
                    }
                }
                .mobile-breadcrum {
                    padding-bottom: 20px;
                }
                .porfolio-wrap {
                    .kyc-user {
                        padding: 10px;
                        .text {
                            font-size: 12px;
                        }
                    }
                }
            }
            .wrap-left {
                width: 100%;
                .left-details {
                    .year-common {
                        font-size: 14px;
                    }
                    .apy-common {
                        font-size: 14px;
                    }
                    .box-img {
                        img {
                            height: 200px;
                        }
                    }
                    .res-tabs-p2p {
                        .ant-tabs-nav-list {
                            width: auto;
                        }
                        .ant-tabs-tab {
                            margin-right: 20px;
                            width: auto;
                            .title-tabs {
                                font-size: 14px;
                            }
                        }
                    }
                    .content-details {
                        .title {
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        .desc {
                            font-size: 13px;
                            margin-bottom: 10px;
                        }
                    }
                    .list-detail-info {
                        .row-info {
                            .item {
                                width: 50%;
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                                .txt-bottom {
                                    font-size: 12px;
                                }
                                .txt-top {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    .content-details {
                        .text-per {
                            font-size: 14px;
                        }
                        .list-per {
                            li {
                                font-size: 14px;
                            }
                        }
                        .note-per {
                            font-size: 14px;
                        }
                        .item-view {
                            align-items: center;
                            .title-view {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.linked-value {
    a {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        margin-bottom: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        margin-top: 15px;
        img {
            width: 110px;
            display: block;
            margin-left: 5px;
        }
    }
}
@media screen and (max-width: 768px) {
    .grow-chart-market .chart-market-box {
        padding: 10px;
    }
    .linked-value a {
        display: block;
        font-size: 14px;
        img {
            margin-top: 5px;
        }
    }
    .chart-market-box {
        canvas {
            height: 400px !important;
        }
    }
}