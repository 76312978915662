.main-header-app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13.5px 20px;
    border-bottom: 1px solid #FFFFFF1A;

    .left-app {
        .title {
            font-family: Radio Canada;
            font-size: 24px;
            font-weight: 500;
            line-height: 28.8px;
            text-align: left;
            color: #fff;
        }
    }

    .right-app {
        .row-item {
            display: flex;
            align-items: center;

            .input-search {
                opacity: 0;
                transition: 0.3s ease;

                &.opacity-1 {
                    opacity: 1;
                }

                .ant-input-group-addon {
                    display: none;
                }

                .ant-input {
                    height: 40px;
                    background: #000;
                    border: 1px solid #0c0d10;
                    border-radius: 8px;
                    width: 400px;
                    color: #fff;
                    border: 1px solid #00EFFF;
                }
            }

            .item {
                margin-left: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &.row {
                    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box,
                    linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    padding: 0 10px;
                    height: 40px;
                    img {
                        width: 20px;
                    }

                }
                .text-wallet {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: #fff;
                    margin-left: 10px;
                }
                &.res {
                    img {
                        width: 90px;
                        height: 40px;
                        display: block;
                    }
                }
                img {
                    width: 40px;
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }
}

::placeholder {
    color: #ffffff86;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ffffff86;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ffffff86;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffff86;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ffffff86;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
}

:focus {
    outline: none;
    box-shadow: 0px 0px 0px #000;
}


.btn-connect {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box,
        linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    box-shadow: 0px 0px 12px 0px #2B79F7 inset;
    padding: 9.5px 23px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.wrong-network .btn-connect {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #5a0102 0%, red 100%) padding-box, linear-gradient(180deg, #f60404 0%, #ff4d4f 100%) border-box;
    border-radius: 50em;
    border: 1px solid red;
    box-shadow: 0px 0px 12px 0px #ff4d4f inset;
    padding: 9.5px 23px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.modal-dis {
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-body {
        padding: 30px;
        text-align: center;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-content {
        background: #000;
        border: 1px solid #00EFFF;
        box-shadow: 0px 0px 12px 0px #2B79F7 inset;
    }

    .ant-modal-header {
        background: #000;
        text-align: center;

        .ant-modal-title {
            font-family: Radio Canada;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4;
            text-align: center;
            color: #fff;
        }
    }

    .btn-connect {
        margin: 0 auto;
    }

    .profile-user {
        text-align: center;
    }
}

.breadcrum-line {
    display: flex;
    align-items: center;

    .item-bread {
        margin-right: 10px;
        display: flex;
        align-items: center;

        img {
            width: 16px;
            opacity: 0.5;
            display: block;
        }

        a {
            display: flex;
            align-items: center;
            color: #ffffff76;
        }

        span {
            padding-top: 3px;
            padding-left: 3px;
        }

        .vector-r {
            img {
                width: 8px;
                opacity: 1;
            }
        }

        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        color: #ffffff76;

        &.active {
            color: #fff;
        }
    }
}

.mar-l-15 {
    margin-left: 15px;
}
.wrap-user-info {
    background: #191B1D;
    border: 1px solid #3B3F4566;
    padding: 15px;
    width: 320px;
    border-radius: 12px;
    margin-right: 20px;
    margin-top: 10px;
    .top-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .box-img {
            margin-right: 15px;
            img {
                width: 48px;
                display: block;
                border-radius: 99px;
                height: 48px;
                object-fit: cover;
            }
        }
        .name-user {
            .line-name {
                margin-bottom: 7px;
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
                img {
                    width: 16px;
                    display: block;
                    margin-left: 5px;
                }
            }
            .line-address {
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #ffffff7e;
                cursor: pointer;
                img {
                    width: 16px;
                    display: block;
                    margin-left: 5px;
                }
            }
        }
    }
    .kyc-row {
        border: 1px solid #00BCD433;
        padding: 10px;
        text-align: center;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;
        color: #00BCD4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        margin-bottom: 15px;
        img {
            width: 16px;
            display: block;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
    .menu-center {
        border-top: 1px solid #3B3F45;
        padding-top: 15px;
        margin-bottom: 15px;
        .list-user-menu {
            margin-left: -10px;
            a {
                display: block;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            li {
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 15px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
                cursor: pointer;
                padding: 15px 10px;
                transition: 0.3s ease;
                border-radius: 8px;
                &:hover {
                    background: #3B3F4566;
                }
                img {
                    width: 16px;
                    display: block;
                    margin-right: 7px;
                }
            }
        }
    }
    .menu-bottom {
        border-top: 1px solid #3B3F45;
        padding-top: 25px;
        .list-user-menu {
            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                font-family: Radio Canada;
                font-size: 15px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    width: 16px;
                    display: block;
                    margin-right: 7px;
                }
            }
        }
    }
}
.dot-live {
    font-size: 10px
    
}
.live-on {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(41, 55, 78, 1);
    padding: 0 14px;
    border-radius: 12px;
    height: 40px;
}
.text-live{
    font-family: Radio Canada;
    font-size: 15px;
    font-weight: 400;
    line-height: 19.2px;
    color: #fff;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .live-on{
        display: none;
    }
    .box-logo-mobile {
        display: none;
    }
    .bar-mobile {
        display: none;
    }
    .header-mobile .right-app {
        width: fit-content;
    }
    .w-4 {
        width: 50% !important;
    }
}

@media screen and (max-width: 768px) {
    .header-mobile {
        .left-app {
            display: none;
        }
        .right-app {
            width: 100%;
            .row-item {
                width: 100%;
                .mobile-left {
                    margin-left: auto !important;
                }
                .live-on {
                    display: none;
                }
                .onclick {
                    display: none;
                }
                .box-logo-mobile {
                    img {
                        width: 90px;
                    }
                }
                .bar-mobile {
                    margin-left: 15px;
                    .box-img {
                        img {
                            width: 42px;
                            display: block;
                        }
                    }
                }
            }
        }
        .btn-connect {
            padding: 8px 12px;
            font-size: 14px;
        }
    }
}
.ant-drawer-content-wrapper {
    width: 100% !important;
}
.drawer-mobile {
    .ant-drawer-body {
        padding: 0;
        background: #000;
    }
}
