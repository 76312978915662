.round-ecosystem {
    padding: 40px 0;
}
.home-buy{
    max-width:200px ;
    width: 100%;
}
.buy-item{
    display: flex;
    justify-content: center;
}
@media screen and (min-width: 769px) and (max-width: 1300px) {
    .grid-cols-3.res-colum-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (max-width: 768px) {
    .logo-landing {
        margin-top: 30px;
        width: 100px;
    }
    .padd-0-15 {
        padding: 0 15px;
    }
}