.ant-input::placeholder {
    color: #ffffff66;
    font-size: 18px;
    font-weight: 500;
}

.mat-top-40x {
    margin-top: 40px;
}

.list-info-supply {
    background: #191B1D;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 15px 0;

    .line-item {
        border-right: 1px solid #AEABFF33;
    }

    .item {
        position: relative;
        padding-left: 10px;

        .top-item {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #707A8A;
        }

        .bottom-item {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #fff;
            margin-top: 10px;
        }
    }
}

.row-layout {
    .row-input-css {
        input {
            &:focus-within {
                border: 0px solid transparent !important;
            }
        }
    }
}

.positon-page {
    position: absolute;
    top: -50px;
    right: 0;
}

.positon-page-row {
    position: absolute;
    top: -60px;
    right: 0;
    .rssss {
        display: flex;
        align-items: center;
    
        span {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff9b;
            margin-right: 10px;
        }
    }
}

.box-filter-type {
    display: flex;
    align-items: center;

    .name-filter {
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
        color: #ffffff9b;
    }
}

.button-cancel {
    .btn-cancel {
        background: #0159F933;
        border: 0px solid transparent;
        padding: 10px;
        border-radius: 4px;
        color: #0159F9;
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        width: 100%;
        &.dis {
            color: #fff;
            background: #99999933;

        }
    }
}
.img-share {
    width: 20px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}
.table-market {
    tr {
        th {
            background: #282b2f !important;
            &:first-child {
                border-radius: 8px 0 0 0 !important;
            }
            &:last-child {
                border-radius: 0 8px 0 0 !important;
            }
        }
        td {
            border-bottom: 1px solid #3b3f4566;
            background: #1a1c1e;
        }
    }
}
.main-table-row {
    .pagination-his {
        .ant-pagination-item-active {
            a {
                color: #fff !important;
            }
        }
    }
}
.w-100-cus {
    width: 100%;
}
.padd-l-10px {
    padding-left: 10px;
}
@media screen and (max-width: 768px) {
    .positon-page-row {
        position: relative;
        top: 0;
        left: 0;
        .status-filter {
            margin-left: inherit !important;
            padding-left: 10px;
        }
    }
    .positon-page {
        position: relative;
        top: 0;
        right: 0;
        width: 30%;
        margin-bottom: 10px;
    }
    .display-tab-mobile {
        .ant-tabs-tab {
            width: 33%;
        }
    }
}
.display-tab-mobile {
    .ant-tabs-nav {
        margin-bottom: 10px;
        .ant-tabs-tab {
            padding: 5px 0;
        }
    }
}
@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 80vw;
        padding: 0;
        left: env(safe-area-inset-left);
        margin: 10px auto;
    }
}
.ant-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: transparent !important;
    .type-his {
       background: #0e491b79;
       border: 1px solid #0e491b; 
    }
}