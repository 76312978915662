
.pto {
    .time-l{
        font-size: 16px !important;
    }
    .time-r{
        font-size: 16px !important;
        color: #4687FF !important;
    }
    .time-countdown{
        min-height: 20.8px;
    }
    // .location-row{
    //     padding-top: 10px;
    // }
    .title-row{
        font-size: 20px !important;
        line-height:1.2 !important;
    }
    .text-loca{
        color: #B7BDC6 !important;
        font-weight: 400 !important;
        padding-right: 10px;
    }
    .raise-profit{
        margin-top: 5px !important;
    }
    .text-process{
        padding-bottom: 5px !important;
    }
    
    .grow-wrap .wrap-left {
        width: 100%;
    }

    .tabs-list .ant-tabs-nav-list {
        width: auto;
    }

    .tabs-list .ant-tabs-tab {
        width: 100%;
        text-align: left;
        padding-right: 30px;
    }

    .ant-item-wrap .guide-item .box-img img {
        border-radius: 16px 16px 0 0;
    }

    .ant-item-wrap .guide-item .type-status {
        color: #FFAF51;
        top: 145px;
        border: 1px solid #FFAF51;
    }
    .target1{
        color: rgba(0, 239, 255, 1);
    }
    .ant-item-wrap .guide-item .type-status.open {
        color: rgba(0, 239, 255, 1);
        top: 145px;
       border: 1px solid rgba(0, 239, 255, 1)
    }
    .ant-item-wrap .guide-item .type-status.coming {
        color: rgba(43, 121, 247, 1);
        top: 145px;
       border: 1px solid rgba(43, 121, 247, 1);
    }  
    .ant-item-wrap .guide-item .type-status.tba {
        color: #fff;
        top: 145px;
       border: 1px solid #ffffff69;
    }
    .ant-item-wrap .guide-item .type-status.par {
        top: 145px;
       border: 1px solid #ef2603;
        color: #ef2603;
    }

    .ant-item-wrap .guide-item .type-process {
        top: 145px;
        left: 25%;
        background: #2B79F7;
        color: #fff;
        border: none;
    }

    .local-left span {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 600;
        line-height: 14.4px;
        color: #fff;
    }

    .local-right span {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 600;
        line-height: 14.4px;
        color: #fff;
    }

    .location img {
        width: 14px;
        height: 14px;
        display: block;
    }

    .location {
        display: flex;
        align-items: flex-start;
        padding-bottom: 20px;
        padding-top: 10px;
        flex-direction: column;
        gap: 10px;
    }

    .local-left {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .local-right {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .box-line1 {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
    }

    .pto-line1 {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: 0.01em;
        color: #B7BDC6;
        text-align: left;
        padding-bottom: 10px;

        display: flex;
        align-items: center;
    }
    .icon-left{
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .pto-line2 {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 600;
        line-height: 14.4px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        text-align: left;
        padding-bottom: 10px;
    }

    .ant-item-wrap .guide-item .bottom-item .location-row {
        padding-bottom: 10px;
    }

    .row-line {
        height: 1px;
        width: 100%;
        background: #3B3F45;
        margin: 10px auto;
    }

    .pto-group {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5px;
        padding-top: 5px;
        flex-direction: column;
    }
    .pto-group-left {
    display: flex;
    gap: 10px;
    align-items: center;
    }

    .pto-group-right {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .ant-item-wrap .guide-item .bottom-item .raise-profit .box-riase {
        width: 100%;
        background: #2B3342;
    }

    .ant-item-wrap .guide-item .bottom-item .bottom-process {
        margin-top: 0;
    }

    .ant-item-wrap .guide-item .bottom-item .bottom-process .text-process .number-process {
        color: #fff;
        font-size: 16px !important;
    }

    .ant-item-wrap .guide-item .bottom-item .raise-profit .box-riase .txt {
        margin-bottom: 0;
        font-size: 16px !important;
        color: #B7BDC6 !important;
    }

    .ant-item-wrap .guide-item .bottom-item .bottom-process .ant-progress-text {
        display: block;
        color: #01efff;
        font-size: 16px !important;
    }

    .filter-grow img {
        width: 24px;
        height: 24px;
        display: block;
    }

    .filter-grow span {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 16.8px;
        color: #fff;
    }

    .filter-dto {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #3B3F4566;
        border: 1px solid #3B3F45;
        border-radius: 8px;
        padding: 6px 16px;
    }

    .filter-dto:hover {
        cursor: pointer;
        background: #3b3f4506;
        transition: 0.3s ease;
    }

}

.detail-pto {
      
    .porfolio-wrap .kyc-user {
        justify-content: center;
        padding: 12px
    }

    .img-item-box img {
        width: 20px;
        height: 20px;
        display: block;
    }

    .item-box-dto {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        width: 33.333%;
    }

    .text-box-dto {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .content-box-dto {
        display: flex;
    }

    .box-dto {
        padding: 20px 0 25px 0;
    }

    .box-row {
        border: 1px solid #3B3F45;
        padding: 12px;
        border-radius: 12px;
    }

    .text-box-1 {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        color: #ffffff92;
    }

    .text-box-2 {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        color: #ffff;
    }

    .line-box {
        border: 1px solid #AEABFF33;
        margin: 15px 0;
    }

    .tabs-list .ant-tabs-tab {
        width: auto;
        padding-right: 25px;
    }

    .tabs-list .ant-tabs-nav-list {
        width: auto;
    }

    .content-project .funding-target .funding-row .bottom-process {
        margin-bottom: 10px;
    }

    .content-project .location-page .status-detail {
        border-radius: 16px;
        margin-right: 8px;
        font-size: 16px;
    }

    .status-detail.new {
        background: #2B79F7;
        color: #fff;
        border: none;
        font-size: 16px;
    }

    .content-project .investment-period {
        margin-top: 12px;
        margin-bottom: 0px;
    }

    .content-project .investment-period .month span {
        font-size: 16px;
        color: #ffffff92;
    }

    .content-project .row-purchase .lends-page .bottom .currentcy {
        width: auto;
        display: flex;
        align-items: center;
        background: transparent;
        border-radius: 8px;
        padding: 10px 2px;
        font-family: Radio Canada;
        font-size: 20px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        justify-content: flex-start;
    }

    .content-project .row-purchase .lends-page .bottom .value-current {
        width: 120px;
    }

    .content-project .row-purchase .lends-page .bottom .value-current .value-top input {
        width: 100%;
        background: rgba(3, 3, 3, 0.4);
        padding: 5px 0;
        height: 40px;
        border-radius: 8px;
    }

    .content-project .row-purchase .lends-page .bottom {
        padding: 0;
        gap: 30px;
        margin-top: 10px;
    }

    .content-project .row-purchase .lends-page .bottom {
        background: none;
    }

    .content-project .row-purchase .lends-page .bottom .value-current .value-top {
        margin-bottom: 0;
        position: relative;
    }

    .plus {
        width: 32px;
        height: 32px;
        color: #000;
        background: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        right: 4px;
    }

    .no-plus {
        width: 32px;
        height: 32px;
        color: #000;
        background: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        left: 4px;
    }

    .plus:hover {
        cursor: pointer;
        transition: 0.2s ease;
        background: #000;
        color: #fff;
    }

    .no-plus:hover {
        cursor: pointer;
        transition: 0.2s ease;
        background: #000;
        color: #fff;
    }

    .content-project .row-purchase .lends-page .bottom .value-current .value-top input {
        text-align: center;
    }
     
}
.pto .grid-cols-4{
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}
.pto .thumbnail-pto{
    height: 240px;
    width: 100%;
}
.progress-pto .ant-progress-bg{
    background: #01efff;
}
.progress-pto .ant-progress-text-outer{
    color: #01efff;
}
.progress-pto .ant-progress-inner{
    background: rgba(255, 255, 255, 0.2);
}
.usdc-child{
    width: 18px;
}
.list-no-data-pto{
    display: flex;
    justify-content: center;
    padding: 100px 0 50px 0;
}
.filter-active{
     background-color: rgba(43, 121, 247, 0.2);
      border-color: rgba(43, 121, 247, 1);
    
}
.p-3 .ant-select-selection-search-input{
    padding-left: 11px !important;
    border-radius: 6px !important;
}
.p-3 .ant-select-selection-search{
    margin: 0 -11px !important;
    border-radius: 6px !important;
}
.btn-actions-pto{
    font-family: "Radio Canada", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.2509803922) inset;
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 0px solid transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}
.btn-actions-pto[disabled]{
    color: #707A8A;
    background: rgba(43, 121, 247, 0.1);
    cursor:no-drop;
    // box-shadow: 1px 1px 1px 1px rgba(239, 239, 239, 0.25) inset;
    pointer-events: none;
}
.btn-claim[disabled]{
    color: #707A8A;
    background: rgba(43, 121, 247, 0.1);
    cursor:no-drop;
    // box-shadow: 1px 1px 1px 1px rgba(239, 239, 239, 0.25) inset;
    pointer-events: none;
}
.btn-claim[disabled]:hover{
     cursor:no-drop;
}
.btn-claim.claimed{
    background: #0048ff89;
    color: #ffffffa9;
    cursor:no-drop;
}

.btn-actions-pto:hover{
     box-shadow: 0 0 10px rgba(83, 148, 255, 1);
}
.btn-actions-pto[disabled]:hover{
    // box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;
    cursor:no-drop;
}
.box-no-data-pto{
    display: flex;
    justify-content: center;
    padding: 50px;
    width: 100%;
}
.detail-status{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    background: #000;
    border-radius: 100px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.detail-status.open{
    color: #00EFFF;
    border: 1px solid #00EFFF;
}
.detail-status.coming{
    color: rgba(43, 121, 247, 1);
    border: 1px solid rgba(43, 121, 247, 1);
}
.detail-status.end{
    border: 1px solid #F4CA5C;
    color: #F4CA5C; 
}
.detail-status.ends{
    border: 1px solid #ef2603;
    color: #ef2603; 
}
.detail-status.tba{
    border: 1px solid #ffffff62;
    color: #fff; 
}
.detail-pto .no-data-page img{
    width: auto;
}
.detail-pto .tabs-list {
    position: relative;
    padding-top: 30px;
}
.title-box-pto {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
}
.num-box-pto{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #fff;
}
.content-box-info-pto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(25, 27, 29, 1);
    border-radius: 12px;
    padding: 16px;
}
.content-box-pto-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
.list-box-detail-pto {
    padding: 24px 0 0 0;
}
.line-height-box {
    border: 0.5px solid rgba(174, 171, 255, 0.2);
    height: 50px;
}

a.link-a-cus {
    color: #1677ff;
}
a.link-a-cus:hover {
    text-decoration: underline;
}
.cus-tab-pto{
    .list-detail-info .row-info .item{
        width: 50% !important;
        border-bottom: 1px solid rgba(174, 171, 255, 0.2) !important;
    }
}
.detail-pto{

.funding-row {
    background: transparent !important;
    padding: 0 !important;
    border-radius: 12px !important;
}
.funding-row .name {
    color: #fff !important;
    font-size: 16px !important;
}
.funding-row .bottom-process{
    margin-top: 5px !important;
}
.target1{
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 239, 255, 1);
}
.target2{
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
}
.bottom-process .ant-progress-text{
    font-size: 16px !important;
}
.time-l{
    font-size: 16px !important;
    color: #fff !important;
}
.row-purchase {
    background: #3B3F45 !important;
    margin-bottom: 15px;
}
.row-purchase .lends-page {
    background: transparent !important;
    padding: 0 !important;
}
.left-lend .text{
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.title{
    font-size: 16px !important;
    padding-right: 3px;
}
.faq-pto{
        font-size: 24px !important;
    }
.month{
    font-size: 20px !important;
    font-weight: 500 !important;
}
.time-countdown{
    flex-wrap: wrap !important;
    gap: 10px !important;
}
}
.title-while{
    color: #fff !important
}
.wrap-before-btn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
.cus-his{
    padding: 15px 0;
    margin-top: 15px;
    .ant-collapse {
    border: 1px solid #3B3F45
    }
    .ant-collapse-item{
        border-radius: 8px !important;
        padding: 3px !important;
        background: transparent !important;
        margin-bottom: 0 !important;
    }
}
.coundown-claim4 {
    padding: 10px 0 5px 0;
}
.boc-time-pto1{
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    .tec-tem{
       display: flex;
       flex-direction: column;
       font-size: 24px;
       font-weight: 500;
       color: #fff;
    }
    .tec-time{
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4784313725);
    }
    .dot-time{
        font-size: 24px;
        font-weight: 500;
        padding: 0 3px;
    }
}
.cus-time{
    margin-top: 0px !important;
    padding-top: 5px !important;
}
.small-status {
    width: fit-content;
    position: absolute;
    z-index: 9;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.dec-timeline-svip p{
    padding-bottom: 5px;
}
.btn-stake-more{
    font-family: "Radio Canada", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.2509803922) inset;
    width: 120px;
    height: 45px;
    border-radius: 8px;
    border: 0px solid transparent;
    color: #fff;
    cursor: pointer;
    margin-top: 12px;
}
.timeline-detail-pto{
    .ant-steps-item-description{
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        color: #fff !important;
    }
    .ant-steps-item-title{
        font-family: Radio Canada;
        font-size: 18px;
        font-weight: 500;
        line-height: 19.2px;
        color: #fff !important;
        text-transform: uppercase;
    }
    .ant-steps-item-finish .ant-steps-item-title{
        color: #00EFFF !important;
    }
    .ant-steps-item-active .ant-steps-item-title{
        color: #00EFFF !important;
    }
    .ant-steps-item-wait .ant-steps-item-tail::after {
        background-color:#D9D9D933 !important;
    }
    .ant-steps-item-wait .ant-steps-icon-dot{
        background: #3B3F45 !important;

    }
    .ant-steps-item-active .ant-steps-item-tail::after {
        background-color:#D9D9D933 !important;
    }
    .ant-steps-item-active .ant-steps-icon-dot{
        background: #00EFFF !important;
    }
    .ant-steps-item-finish .ant-steps-icon-dot{
        background: #00EFFF !important;
    }
    .ant-steps-item-finish .ant-steps-item-tail::after{
        background-color: #00EFFF !important;
    }
    .ant-steps-item-icon{
        width: 10px !important;
        height: 10px !important;
    }
    .ant-steps-item-tail{
        padding: 20px 0 0 !important;
    }
    .ant-steps-item-tail::after{
        margin-inline-start: 4px !important;
    }
    
}
.time-line-pto .ant-steps-vertical{
        gap: 12px;
    }
.stt-com-img img{
    border-radius: 12px;
    border: 1px solid #3B3F45;
}
.stt-complete{
    position: relative;
    margin-bottom:  15px;
}

.group-claim {
    background: #1C1C1C;
   border: 1px solid #3B3F45;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    align-items: center;
    padding: 12px;
}
.title-claim {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}
.btn-claim {
    border-radius: 8px;
    padding: 12px 16px;
    background: #0159F9;
    box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}
.name-cus1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Radio Canada;
    line-height: 1.4;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    font-size: 20px !important;
    font-weight: 600;
}
.name-cus{
    color: #FFFFFF;
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    padding-bottom: 5px;
    padding-top: 3px;
}
.number-cus{
    color: #FFFFFF !important;
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
}
.num-claim{
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}
.num-claim span{
     font-size: 20px;
    font-weight: 500;
    color: #ffffff72;
}
.row-claim {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}
.stt-com-text1{
    padding-bottom: 5px;
}

.stt-com-text{
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #fff;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px;
}
.his-cus{
    .item:nth-child(1){
        width: 20% !important;
    }
    .item:nth-child(2){
        width: 20% !important;
    }
    .item:nth-child(3){
        width: 30% !important;
    }
    .item:nth-child(4){
        width: 30% !important;
    }
    .bottom-pur{
    padding: 10px 0 !important;
    }
    .row-tsx{
    padding-top: 5px;
    }
    .bottom-pur{
        .item:nth-child(3){
        color: #fff !important;
    }
    }
}
.cound-down-all img{
    width: 18px;
    height: 18px;
    display: block;
}
.minted-nft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
}
.coundown-claim3{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    color: #fff;
    padding-bottom: 5px;
    padding-top: 5px ;
}
.coundown-claim {
    width: 100%;
    background: #030303;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 0px #2B79F7;

}
.minted-right{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #ffffff;
}
.minted-right span{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #ffffffae;
}
.cound-down-all {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #000000C2;
    padding: 8px 14px;
    backdrop-filter: blur(8px);
    border-radius: 8px;
}
.boc-time-pto-all{
    font-size: 16px;
    font-weight: 500;
}
.boc-time-pto-all .tec-tem{
    background: transparent;
}
.boc-time-pto-all .tec-time{
    color: #fff;
}
.brcum-pto{
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    padding: 10px 0;
}
.btn-actions-pto{
    margin-bottom: 15px;
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .pto .w-3 {
        width: calc(4 / 12 * 100%);
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .pto .w-3 {
        width: calc(6 / 12 * 100%);
    }
}
@media screen and (max-width: 1024px) {
    .pto .w-3 {
        width: calc(6 / 12 * 100%);
    }
}
@media screen and (max-width: 768px) {
    .pto .w-3 {
        width: 100%;
    }
    .pto .columns{
        margin-top: 10px;
        gap: 10px;
    }
    .mobie-tab{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
    }
    .mobie-tab-item{
        overflow-x: auto;
    }
    .mobie-tab-item .bg-primary {
        background-color: transparent;
    }
    .pto .title-row{
        font-size: 18px !important;
    }
    .pto .local-left span{
        font-size: 15px;
    }
    .pto .local-right span{
        font-size: 15px;
    }
    .pto .pto-line1{
        font-size: 15px;
    }
    .pto .pto-line2{
        font-size: 15px;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .raise-profit .box-riase .txt{
        font-size: 15px !important;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .bottom-process .text-process .number-process{
        font-size: 15px !important;
    }
    .faq-mobie .collapse-faq .title{
        font-size: 20px;
    }
    .faq-mobie .ant-col-12{
        width: 100%;
        max-width: 100%;
    }
    .detail-pto .breadcrum-line{
        padding: 20px 0;
    }
    .section-grow.detail-pto .grow-wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
    }
    .section-grow.detail-pto .grow-wrap .wrap-left {
        width: 100%;
    }
    .left-details .box-img img{
        height: 200px;
    }
    .detail-status{
        font-size: 14px;
        padding: 5px 10px;
    }
    .small-status{
        top: 10px;
        left: 10px;
    }
    .content-box-info-pto{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 10px;

    }
    .content-box-info-pto-mo {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
    }
    .content-box-pto-item{
        width: calc(50% - 5px);
    }
    .title-box-pto,.num-box-pto{
        font-size: 15px;
    }
    .pto .tabs-list .ant-tabs-tab-active .ant-tabs-tab-btn .title-tabs{
        font-size: 15px;
    }
    .item-pto-pd{
        width: 50% !important;
    }
    .detail-pto .wrap-right{
        width: 100% !important;
        padding-top: 25px;
    }
    .detail-pto .content-project{
        padding-left: 0;
    }
    .boc-time-pto1 .tec-tem{
        font-size: 22px;
    }
    .detail-pto .content-project .location-page .status-detail{
        font-size: 14px;
    }
    .detail-pto .content-project .name-detail{
        font-size: 22px;
    }
    .detail-pto .time-l {
        font-size: 15px !important;
    }
    .coundown-claim4 {
        padding: 5px 0 5px 0;
    }
     .detail-pto .time-r{
        font-size: 15px !important;
    }
     .detail-pto .funding-row .name{
        font-size: 15px !important;
     }
     .detail-pto .number-cus{
         font-size: 15px !important;
     }
     .detail-pto .name-cus1{
        font-size: 18px !important;
     }
     .stt-com-text{
        font-size: 22px;
     }
     .timeline-detail-pto .ant-steps-item-title{
        font-size: 16px;
     }
     .timeline-detail-pto .ant-steps-item-description{
         font-size: 15px;
     }
     .minted-right{
        font-size: 15px;
     }
     .minted-right span{
        font-size: 15px;
     }
     .detail-pto .faq-pto {
        font-size: 22px !important;
    }
    .detail-pto .ant-row{
        flex-direction: column;
    }
    .cus-tab-pto .list-detail-info .row-info .item{
        width: 100% !important;
    }
    .detail-pto .target1{
        font-size: 18px;
    }
    .detail-pto .target2{
        font-size: 18px;
    }
    .pto .ant-row{
        flex-direction: column;
    }
    .boc-time-pto-all{
        font-size: 15px;
    }
    .cound-down-all{
        padding: 5px 10px;
    }
    .cus-his{
        padding: 15px 0 !important;
    }
    .cus-time{
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .his-cus{
        width: 500px;
    }
    .cus-his .ant-collapse-content-box{
        overflow-x: auto;
    }
    .detail-pto .ant-collapse-header{
        padding: 5px 10px !important;
    }
    .detail-pto .ant-collapse-expand-icon{
        top: 5px !important;
    }
    .detail-pto .content-project .investment-period{
        margin-top: 5px;
    }
    .detail-pto .month{
        font-size: 18px !important;
    }
    .detail-pto .title{
        font-size: 15px !important;
    }
    .pto .ant-collapse-item{
        padding: 8px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .content-box-info-pto-mo {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
    }
    .content-box-info-pto{
        gap: 15px;
    }
    .section-grow .grow-wrap .wrap-right{
        width: 100%;
        padding-top: 25px;
    }
    .ant-item-wrap .guide-item .name-type.res{
        top: 130px;
    }
    .mobie-tab-item{
        font-size: 14px !important;
    }
    .pto .title-row {
    font-size: 16px !important;
    line-height: 1.5 !important;
    }
    .pto .local-left span{
        font-size: 15px;
    }
    .pto .local-right span{
        font-size: 15px;
    }
    .pto .pto-line1{
        font-size: 15px;
    }
    .pto .pto-line2{
        font-size: 15px;
    }
    .pto .pto-group{
        gap: 10px;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .raise-profit .box-riase{
        padding: 5px;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .raise-profit .box-riase .txt{
        font-size: 15px !important;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .bottom-process .text-process .number-process{
        font-size: 15px;
    }
    .pto .ant-item-wrap .guide-item .bottom-item .bottom-process .ant-progress-text{
        font-size: 15px;
    }
    .pto .ant-collapse-item{
        padding: 8px;
    }
}