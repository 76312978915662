.ant-table-cell {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #fff;   
    padding: 15px !important;
}
// .res-class-item {
//     width: 100px;
//     justify-content: center;
// }\
.tabs-list {
    width: 100%;
}
.btn-buy-market {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    padding: 9.5px 23px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    &.dis {
        background: #666666;
        pointer-events: none;
    }
}
.img-thumb {
    width: 60px;
    border-radius: 2px;
}
.row-mobile-list-mk {
    margin-top: 15px;
    .content-item-mobile-mk {
        background: #191B1D;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 5px;
            &:last-child {
                padding-bottom: 0;
            }
            .txt-l {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #ffffff88;   
            }
            .txt-r {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #fff;   
                &.name {
                    color: #0159F9;
                }
                &.price {
                    color: #00D415;
                }
            }
        }
    }
}
.res-mobile-content {
    .content-details {
        .title {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .desc {
            font-size: 13px;
            margin-bottom: 10px;
        }
        .list-detail-info {
            .row-info {
                .item {
                    width: 50%;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    .txt-bottom {
                        font-size: 12px;
                    }
                    .txt-top {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
.res-mobile-grow {
    width: 100%;
    .tv-lightweight-charts {
        width: 100% !important;
        table {
            width: 100% !important;
        }
    }
    .porfolio-wrap {
        .kyc-user {
            padding: 10px;
            .text {
                font-size: 12px;
            }
        }
    }
    .list-info-supply {
        .item {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bottom-item {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .line-item {
            display: none;
        }
    }
}
