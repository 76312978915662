.header-landing {
    background: rgb(0, 0, 0, 90);
    padding: 0 10px;
    font-family: "Radio Canada", sans-serif;
}

.content-header-land {
    max-width: 100%;
    padding: 10px;
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.header-land-left {
    display: flex;
    gap: 25px;
    align-items: center;
}

.logo-header-land {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-right: 30px;
}

.logo-header-land:hover {
    cursor: pointer;
}

.img-logo-land img {
    display: block;
    width: 26px;
    height: 29px;
}

.name-logo-land {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
}

.item-header-land {
    color: #fff;
    font-size: 16px;
    transition: 0.3s ease;
}

.item-header-land:hover {
    cursor: pointer;
    color: rgba(68, 134, 255, 0.753);

}

.btn-launch-app {
    background: rgb(0, 188, 212);
    background: radial-gradient(circle, rgba(0, 188, 212, 1) 0%, rgba(0, 71, 255, 1) 100%);
    cursor: pointer;
    border-radius: 12px;
    height: 40px;
    width: 135px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #00efff;
    border-bottom: 0px;
}

.btn-launch-app:hover {
    background: #000;
    border: 1px solid #00efff !important;
}

.footer-landing {
    background: #000;
    display: flex;
    justify-content: center;
}

.content-footer-lan {
    width: 1280px;
    max-width: 100%;
}

.lan-item1-img img {
    width: 150px;
    height: 40px;
}

.lan-item1-dec {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
}

.footer-lan-bot {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

.footer-lan-item2 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}

.footer-lan-item3 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
}

.footer-lan-item4 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
}

.footer-lan-top {
    display: flex;
    background: rgba(8, 92, 252, 0.1);
    border-top: 1px solid rgba(68, 132, 255, 0.6);
    border-radius: 16px;
    padding: 0 10px;
}

.lan-item1-img {
    padding-bottom: 24px;
}

.footer-lan-item1 {
    width: 55%;
    text-align: start;
    padding: 30px 60px;
}

.footer-lan-item2 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-item3 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-item4 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-bot1 {
    display: flex;
    gap: 60px;
    margin-left: 10px;
}

.footer-lan-item2-line {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 15px;
}

.footer-lan-item2-title {
    padding-bottom: 27px;
}

.footer-lan-item2-line:hover {
    color: rgba(68, 132, 255, 0.6);
    transition: 0.3s ease;
    cursor: pointer;
}

.list-icon-footer img {
    width: 25px;
    height: 25px;
}

.list-icon-footer {
    display: flex;
    gap: 15px;
    align-items: center;
}

.list-icon-footer img:hover {
    cursor: pointer;
}

.policy:hover,
.term:hover {
    color: rgba(68, 132, 255, 0.6);
    transition: 0.3s ease;
    cursor: pointer;
}

.item-header-land a {
    color: #fff;
}

.item-header-land a:hover {
    color: rgba(68, 132, 255, 0.6);
    transition: 0.3s ease;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .footer-lan-item2 {
        width: 50%;
        padding-bottom: 5px;

    }

    .footer-lan-item3 {
        width: 50%;
        text-align: center;
        padding-bottom: 5px;
    }
}

.modal-mobie .ant-modal-content{
    background: #030303;
    border: 1px solid #085CFC99;
    border-radius: 16px;
}
.modal-mobie .ant-modal-header{
     background: #030303;
}
.modal-mobie .ant-modal{
    top: 150px;
}
.modal-mobie .ant-modal-close{
    color: #fff;
}
.img-modal-mobie {
    width: 110px;
    height: 110px;
    display: block;
}
.text-modal-mobie {
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #2B79F7;
    text-align: center;
}
.dec-modal-mobie {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #fff;
    text-align: center;
}
.modal-mobie-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

@media screen and (max-width: 768px) {
    .header-landing {
        padding: 0 10px;
    }

    .item-header-land {
        display: none;
    }

    .content-footer-lan {
        padding-top: 100px !important;
    }

    .footer-lan-top {
        flex-direction: column;
        padding: 16px;
    }

    .footer-lan-item1 {
        width: 100%;
        padding: 0;
    }

    .footer-lan-item2 {
        flex-grow: 1;
    }

    .footer-lan-item3 {
        text-align: left;
        width: unset;
    }

    .mo-fli {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-bottom: 5px;
    }

    .footer-lan-item4 {
        width: 100%;
        padding: 0;
    }

    .footer-lan-bot {
        flex-wrap: wrap;
        padding: 15px 0;
    }

    .footer-lan-bot1 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 15px;
        justify-content: center;
    }

    .all-right {
        width: 100%;
    }

    .footer-lan-item2-line {
        font-size: 14px;
    }
}
.res-footer {
    width: 100%;
    padding: 20px 20px 0 20px;
}
.content-footer-lan {
    width: 100%;
}