

.content-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 35px;
}
.banner-top {
    width: calc(8 / 12* 100% - 12px);
}
.info-top {
    width: calc(4 / 12* 100% - 12px);
}
.banner-top img{
    width: 100%;
    height: auto;
}
.title-early img{
    display: block;
    width: 20px;
    height: 20px;
}
.title-early span{
    color: #fff;
    font-weight: 600;
    font-family: Radio Canada;
    font-size: 22px;
    line-height: 26.4px;
}
.title-early {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 24px;
}
.container-staking-list {
    padding: 20px 30px;
    font-family: Radio Canada;
}
.ske-list-stake {
    display: flex;
    gap: 20px;
}
.total-stake-top {
    background: #2B79F71A;
    text-align: start;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 12px;
}
.title-total-top{
    color: #ffffffc0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    padding-bottom: 8px;
}
.num-total-top{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.num-total-top span{
    color: #ffffffc0;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.top-total-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    padding-bottom: 0;
}
.top-total-text{
    color: #ffffffc0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}
.top-total-amount{
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
    font-weight: 500;
}
.logo-item-project img{
    width: 60px;
    height: 60px;
    border-radius: 99px;
}
.icon-day img{
    width: 16px;
    height: 16px;
    display: block;
}
.item-stake {
    border: 1px solid #2B79F752;
    border-radius: 16px;
}
.item-stake:hover{
    border: 1px solid #2b79f7cd;
    transition: 0.3s ease;
}

.wrap-item-stake {
    width: calc(4 / 12* 100% - 24px);
}
.list-item-stake {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    align-items: flex-start;
}
.item-stake-top {
    background: url('./img/bg-top-item.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    padding-bottom: 90px;
    padding-top: 10px;
    border-radius: 16px;
    gap: 10px;
}
.item-stake-top1 {
    background: url('./img/bg-top-item1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    padding-bottom: 90px;
    padding-top: 10px;
    border-radius: 16px;
    gap: 10px;
}
.item-stake-top2 {
    background: url('./img/bg-top-item2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    padding-bottom: 90px;
    padding-top: 10px;
    border-radius: 16px;
    gap: 10px;
}
.stake-top-left {
    display: flex;
    align-items: center;
    gap: 10px;
}
.content-item-topr {
    display: flex;
    gap: 8px;
    align-items: center;
    background: #030303;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #2B79F7;
}
.name-item-top{
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #fff;
    padding-bottom: 8px;
     text-align: left;
}
.apy-item-top{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    color: #00EFFF;
    text-align: left;
}
.lock-day-top{
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #fff;
    margin-top: 2px;
}
.line-body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
.line-body-left{
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
color: #ffffffc0;
}
.line-body-right{
font-size: 14px;
font-weight: 500;
line-height: 16.8px;
color: #fff;
}
.item-countdown {
    margin-top: -65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-bottom: 10px;
}
.num-donw {
    font-size: 16px;
}
.text-down {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.4px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.item-stake-body {
    background: #030303;
    padding: 8px;
    padding-top: 15px;
    border-radius: 16px;
    border-top: 1px solid #2B79F7;
    position: relative;
    z-index: 9;
}
.line-body-right.apy{
    color: #2B79F7;
}
.pool-cap {
    background: rgba(43, 121, 247, 0.1);
    border-radius: 8px;
    padding: 8px;
    padding-top: 12px;
    margin-top: 5px;
}
.mid-content3{
    color: #fff;
}
.title-pool-cap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}
.calcu-apr{
    color: #2B79F7;
    font-weight: 500;

}
.all-collapse .ant-collapse-item {
    width: 100% !important;
    border: 0px solid transparent;
    margin-bottom: 10px;
}
.all-collapse .ant-collapse{
    display: block;
}
.collapse1 {
    width: calc(50% - 10px);
}
.collapse2 {
     width: calc(50% - 10px);
}
.all-collapse {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.stake-link {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
}
.img-success {
    display: flex;
    justify-content: center;
}
.title-cap-left{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #ffffffc0;
}
.title-cap-right{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #ffffffc0;
}

.title-cap-left.second{
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #ffffff;
}
.title-cap-right.second{
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #ffffff;
}

.cap-progress .ant-progress-inner{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 1) inset;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 1) inset;
    height: 10px;
}
.cap-progress .ant-progress-bg.ant-progress-bg-outer{
    height: 100% !important;
}
.cap-progress .ant-progress-text.ant-progress-text-end.ant-progress-text-outer{
    color: #2B79F7;
    font-weight: 500;
}
.btn-stake {
    width: 100%;
    border: none;
    height: 40px;
    border-radius: 8px;
    background: rgba(8, 92, 252, 1);
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;

    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #ffffff;
}
.btn-stake:hover{
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background: rgb(3, 46, 126);
}
.button-stake {
    padding-top: 16px;
}

.btn-status {
    display: flex;
    align-items: center;
    height: 45px;
    width: 200px;
    border: 1px solid rgba(41, 55, 78, 1);
    border-radius: 12px;
    justify-content: space-evenly;
}
.site-status {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    gap: 20px;
}

.btn-stt-live-acc{
    height: 35px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(43, 121, 247, 1);
    border-radius: 8px;
    cursor: pointer;

    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
}
.btn-stt-live{
    height: 35px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    cursor: pointer;

    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
}
.btn-stt-finis-acc{
    height: 35px;
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(43, 121, 247, 1);
    border-radius: 8px;
    cursor: pointer;

    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
}
.btn-stt-finis{
    height: 35px;
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    cursor: pointer;

    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
}
.btn-only .ant-switch.ant-switch-small {
    background: rgba(41, 55, 78, 1);
}
.btn-only .ant-switch.ant-switch-checked{
    background: #4096ff !important;
}
.box-no-data-stake {
    display: flex;
    justify-content: center;
    padding: 50px;
}
.img-empty{
    width: 120px;
    height: 120px;
}
.stake-only{
    padding-left: 5px;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
}

.btn-only {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .banner-staking{
        display: none;
    }
    .all-collapse {
        flex-wrap: wrap;
        gap: 0;
    }
    .collapse1 {
        width: 100%;
    }
    .collapse2 {
        width: 100%;
    }
    .collapse-faq .ant-collapse-header-text {
        font-size: 14px !important;
    }
    .collapse-faq {
        padding: 40px 0 !important;
    }
    .collapse-faq .title {
        font-size: 20px !important;
    }
    .collapse-faq .ant-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .collapse-faq .ant-collapse-item {
        padding: 0px !important;
    }
    .container-staking-list {
        padding: 20px;
    }
    .content-top {
        align-items: center;
        flex-wrap: wrap;
        gap: 14px;
        padding-bottom: 25px;
        flex-direction: column;
    }
    .banner-top {
        width: 100%;
    }
    .info-top{
        width: 100%;
        padding-bottom: 15px;
    }
    .total-stake-top{
        margin-bottom: 6px;
    }
    .site-status{
        justify-content: space-between;
    }
    .list-item-stake{
        justify-content: center;
    }
    .wrap-item-stake{
        width: 100%;
    }
    .item-bread-staking {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        font-size: 24px;
        font-weight: 600;
    }
    .stake-only{
        font-size: 13px;
    }
    .item-countdown{
        margin-top: -85px;
    }
    .num-donw {
        font-size: 14px;
    }
    
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .item-bread-staking{
        display: none;
    }
    .wrap-item-stake {
        width: calc(6 / 12* 100% - 24px);
    }
    .banner-staking-mo{
        display: none;
    }
    .item-bread-staking{
        display: none;
    }

}
@media screen and (min-width: 1025px)  {
.banner-staking-mo{
        display: none;
    }
    .item-bread-staking{
        display: none;
    }
}