.menu-wrap {
    background: #000;

    .menu-sidebar {
        padding: 20px;
        &.collapse-menu {
            transition: 0.3s ease;
            .sidebar-icon {
                right: -27px !important;
            }
            .sidebar-center {
                .ant-menu {
                    .ant-menu-title-content {
                        display: none;
                    }
                    .ant-menu-item {
                        width: fit-content;
                    }
                }
            }

            .sidebar-bottom {
                .row-contact {
                    .item {
                        padding-left: 13px;
                        height: 45px;
                        width: 45px;
                        margin-left: 5px;
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
        .sidebar-top {
            padding: 0 0 20px 0;
            position: relative;
            .sidebar-icon {
                position: absolute;
                right: 0;
                top: 6px;
                cursor: pointer;
                img {
                    display: block;
                    width: 16px;
                }
            }
        }

        .sidebar-center {
            padding: 20px 0 0 0;
            .ant-menu {
                background: transparent;
                .ant-menu-item {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    height: 45px;
                    line-height: 45px;
                    border: 1px solid transparent;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    img {
                        width: 16px;
                        height: 16px;
                        filter: opacity(0.5);
                        &:nth-child(1) {
                            display: block;
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    svg {
                        margin-right: 10px;
                        path {
                            fill: #ffffff7a;
                        }
                    }

                    .ant-menu-title-content {
                        font-family: "Radio Canada", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.2;
                        text-align: left;
                        padding-top: 2px;
                    }
                }

                .ant-menu-submenu-title {
                    height: 45px;
                    line-height: 45px;
                    display: flex;
                    align-items: center;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    img {
                        width: 16px;
                        display: block;
                        margin-right: 10px;
                        filter: opacity(0.5);
                    }

                    .ant-menu-title-content {
                        font-family: "Radio Canada", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        padding-top: 2px;
                    }
                }

                .ant-menu-item-selected {
                    border: 1px solid #00EFFF;
                    box-shadow: 0px 0px 12px 0px #2B79F7 inset;
                    background-color: transparent;
                    img {
                        filter: opacity(1);
                        &:nth-child(1) {
                            display: none;
                        }
                        &:nth-child(2) {
                            display: block !important;
                            filter: opacity(1);
                        }
                    }
                   
                    svg {
                        margin-right: 10px;
                        path {
                            fill: #085CFC;
                        }
                    }
                }
            }
        }
        .sidebar-bottom {
            margin-top: 200px;
            border-top: 1px solid #FFFFFF1A;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            padding: 20px;
            .row-contact {
                .item {
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    height: 45px;
                    line-height: 45px;
                    transition: 0.3s ease;
                    border-radius: 8px;
                    cursor: pointer;
                    border: 1px solid transparent;
                    &:hover {
                        border: 1px solid #00EFFF;
                        box-shadow: 0px 0px 12px 0px #2B79F7 inset;
                        span {
                            color: #fff;
                        }
                        img {
                            filter: opacity(1);
                        }
                    }
                    img {
                        width: 16px;
                        display: block;
                        margin-right: 10px;
                        filter: opacity(0.5);
                        transition: 0.3s ease;
                    }
                    span {
                        font-family: "Radio Canada", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.2;
                        text-align: left;
                        color: #ffffff91;
                        padding-top: 2px;
                        transition: 0.3s ease;
                    }
                    svg {
                        opacity: 0.5;
                        fill: #fff;
                        path {
                            fill: #fff;
                        }
                        filter {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}
.icon-mobile {
    display: none;
}
.sidebar-mobile {
    display: block;
}
.menu-wrap .menu-sidebar .sidebar-bottom{
    padding: 10px 20px 10px 20px;
}
@media screen and (max-width: 768px) {
    .sidebar-mobile {
        display: none;
    }
    .menu-wrap .menu-sidebar .sidebar-center .ant-menu .ant-menu-item-selected .icon-mobile img:nth-child(1) {
        display: block;
    }
    .menu-sidebar {
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon-mobile {
                display: block;
                img {
                    width: 16px;
                }
            }
        }
    }
}

@media screen and (min-height: 200px) and (max-height: 991px) {
    .menu-wrap .menu-sidebar .sidebar-center {
        padding: 20px 0 0 0;
        overflow-y: auto;
        height: calc(100vh - 150px);
    }
}