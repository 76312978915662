

.title-vest{
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    color: #fff;
    padding-bottom: 6px;
}
.dec-vest{
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.21px;
    padding-bottom: 24px;
    color: rgba(112, 122, 138, 1);
}
.content-vest {
    background: rgba(43, 121, 247, 0.1);
    max-width: 900px;
    margin: 0 auto;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 24px;
}
.container-vesting {
    padding: 12px 20px;
}
.item-vest1 {
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    padding-bottom: 4px;
}
.item-vest1 span{
    color: rgba(112, 122, 138, 1);
}
.item-vest2 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(112, 122, 138, 1);
}
.item-vest {
    background: rgba(43, 121, 247, 0.1);
    padding: 16px 16px 33px 16px;
    border-radius: 12px;
    min-height: 110px;
    text-align: start;
    transition: all 500ms ease;
}
.item-vest:hover{
    background: #072a77;
}
.list-item-vest {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.wrap-item-vest{
    width: calc(25% - 20px);
    
}
.text-unlock {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    padding: 24px 0 16px 0;
    color: rgba(0, 239, 255, 1);

}
.num-unlock {
    font-family: Radio Canada;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    text-align: left;
    color: #fff;
    background: rgba(3, 3, 3, 1);
    padding: 12px;
    border-radius: 6px;
}
.num-unlock span{
    color: rgba(112, 122, 138, 1);
}
.view-sc {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: rgba(83, 148, 255, 1);
    transition: all 500ms ease;
}
.view-sc:hover{
    cursor: pointer;
    color: #fff;
   

}

.ac-wd {
    display: flex;
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    width: 300px;
    height: 40px;
    color: #fff;
    background: rgba(8, 92, 252, 1);
    /* box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset; */
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: all 500ms ease;
}
.unlocked{
    color: rgba(0, 239, 255, 1);
}
.ac-wd[disabled]{
    color: #707A8A;
    background: rgba(43, 121, 247, 0.1);
    cursor:no-drop;
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;
    pointer-events: none;
}
.ac-wd:hover{
     box-shadow: 0 0 10px rgba(83, 148, 255, 1);
     cursor: pointer;
}
.ac-wd[disabled]:hover{
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;
    cursor:no-drop;
}


.action-vest {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 24px 0;
}
table.tb-vest {
    width: 100%;
    border-radius: 6px;
}
.table-vest {
    background: #000;
    padding: 10px;
    margin-top: 20px;
}
.tb-header{
    background: rgba(59, 63, 69, 0.4);
    
}
.tb-header th{
    padding: 10px 4px;
}
.tb-body td{
    padding: 4px;
    padding-top: 20px;
    padding-bottom: 20px;
}



.tb-vest .tb-body {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
    transition: all 500ms ease;
}
.tb-vest .tb-body:hover{
    background: rgba(59, 63, 69, 0.4);
    cursor: pointer;
}
.tb-vest .tb-body td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
.tb-vest .tb-body td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }


.text-blu{
    color: rgba(83, 148, 255, 1);

}


.tb-header th:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
.tb-header th:last-child {
    border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
}







/* loading css */
/* HTML: <div class="loader"></div> */
.loader {
  width: 22px;
  --b: 6px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#1677ff) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}