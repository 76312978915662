@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    text-align: center;
    background: rgba(0, 2, 18, 1);
    overflow-x: hidden;
}

.btn-cus[disabled] {
    /* background: rgba(43, 121, 247, 0.1); */
    background: rgba(142, 142, 142, 1);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #F90;
    background-image: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%);
}


.ant-layout-content {
    color: #fff !important;
    background: #000 !important;
}

.text-cd-vest {
    color: #fff !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

html {
    scroll-behavior: smooth;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
}

.custom-class-noti span {
    word-break: break-word;
}

.ant-message {
    z-index: 999999999999;
}

.App-link {
    color: #61dafb;
}

.Toastify__toast {
    background: #000 !important;
    border: 1px solid #00EFFF !important;

}

.Toastify__toast>div {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    color: #fff;
}

.Toastify__toast-container {
    z-index: 9999 !important;
}

.Toastify__toast-container--top-right {
    top: 68px !important;
}

.gradient-button {
    font-family: "Radio Canada", sans-serif;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
    border-radius: 8px;
    border: 0px solid transparent;
    color: #fff;
    cursor: pointer;

    &.dis {
        background: rgba(43, 121, 247, 0.1019607843);
        color: #ffffff91;
        pointer-events: none;
        box-shadow: none;

        .ant-spin {
            margin-left: 10px;
            color: #fff;
        }
    }
}

.search-input>.ant-input::placeholder {
    color: white !important;
}

#table-referral {
    &.ant-table {
        background: #1A1C1E !important;
    }

    .ant-table-thead>tr>.ant-table-cell {
        background: rgba(59, 63, 69, 0.40) !important;
        color: #ffffffcc !important;
        border: none !important;
    }

    .ant-table-thead>tr>.ant-table-cell::before {
        display: none !important;
    }

    .ant-table-tbody>.ant-table-placeholder,
    .ant-table-tbody>.ant-table-placeholder:hover {
        background: rgba(59, 63, 69, 0.40) !important;

        .ant-table-cell {
            border: none !important;
        }

        .ant-empty-description {
            color: white !important;
        }
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .custom-class .ant-message-notice-content {
        display: block !important;

    }

    #swap-container {
        width: calc(100vw - 20px) !important;
    }
}

.ant-popover-inner {
    padding: 0 !important;
}


.ant-table-thead>tr>th {
    border: none !important;
    border-right: none !important;
}

.ant-pagination-item:not(.ant-pagination-item-active),
.ant-pagination-jump-next {
    border: 1px solid #3B3F45;
}

.ant-pagination-item-active>a,
.ant-pagination-item-ellipsis {
    color: #FFF !important;
}

#table-striped .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
}

.custom-modal {
    .ant-modal-content {
        padding: 0 !important;
        border-radius: 16px !important;
        border: 1px solid rgba(43, 121, 247, 0.4) !important;
        background: #030303 !important;
    }
}