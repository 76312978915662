
.pagination-his {
    padding: 10px 0;
    text-align: center !important;
}
.pagination-his .ant-pagination{
    font-family: Radio Canada ;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
}
.pagination-his .ant-pagination-item-link{
    background-color: #3b3f45 !important;
}
.pagination-his .ant-pagination .ant-pagination-item a {
    color: #fff;
}
.pagination-his .ant-pagination-item-active a{
    color: #000 !important;
}
.pagination-his .ant-pagination-item-link{
    color: #fff !important;
}
.pagination-his .ant-pagination-disabled .ant-pagination-item-link{
    color: rgba(0, 0, 0, 0.25) !important;
}
.type-his{
    font-family: Radio Canada ;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #fff;
    border: 1px solid rgba(75, 80, 87, 0.664);
    padding: 10px;
    width: 120px;
    border-radius: 12px;
    transition: all 0.3s ease;
}
.ant-dropdown .ant-dropdown-menu{
     background: #191B1D;
}
.type-his.success:hover{
    color: #00D415;
    background: #00D4151A;
}
.type-his.pending:hover{
    color: #DEC43B;
    background: #DEC43B1A;
}
.type-his.fail:hover{
    color: #B43C3C;
    background: #B43C3C1A;
}
.dropdown-type .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child{
    padding: 5px;
}
.bg-success{
    color: #00D415 !important;
    background: #00D4151A !important;
}
.bg-pending{
    color: #DEC43B !important;
    background: #DEC43B1A !important;
}
.bg-fail{
    color: #B43C3C !important;
    background: #B43C3C1A !important;
}
.nodata-loading{
    text-align: center !important;
    padding: 80px 0 !important;
}





.load-wrapp {
    height: 135px;
    margin: 0 10px 10px 0;
    padding: 20px 20px 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #d8d8d8;
}
.line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #4e73df;
}
.load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
}
@keyframes  loadingC {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0);
    }
}