.row-layout {
    .ant-layout-content {
        margin-top: 68px;
    }
    .flex-layout {
        display: flex;
        flex-wrap: wrap;
        height: 100vh;

        &.flex-layout-collapse {
            .left {
                width: 100px;
                background: #000;
                border-right: 1px solid #FFFFFF1A;
                transition: 0.3s ease;
            }

            .main-header-app {
                width: calc(100% - 100px);
            }

            .right {
                width: calc(100% - 100px);
                background: #000;
                color: #fff;
                transition: 0.3s ease;

            }
        }

        .left {
            width: 225px;
            background: #000;
            border-right: 1px solid rgba(255, 255, 255, 0.1019607843);
            position: fixed;
            height: 100%;
            z-index: 999999;
        }

        .right {
            width: calc(100% - 225px);
            background: #000;
            color: #fff;
            overflow-y: auto;
            min-height: 100vh;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            margin-left: auto;
        }
        .main-header-app {
            position: fixed;
            right: 0;
            width: calc(100% - 225px);
            z-index: 99999;
            background: #000;
        }
    }
}

#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.App .ant-layout {
    background: #000;
}
.flew-mobile-responsive {
    display: block;
}
@media screen and (max-width: 768px) {
    .mobile-layout.row-layout .flex-layout.flex-layout-collapse .right {
        width: 100%;
    } 
    .mobile-layout.row-layout .flex-layout.flex-layout-collapse .main-header-app {
        width: 100%;
    }
    .mobile-layout.row-layout .flex-layout .right {
        width: 100%;
    } 
    .mobile-layout.row-layout .flex-layout .main-header-app {
        width: 100%;
    }
    .flew-mobile-responsive {
        display: none;
    }
}