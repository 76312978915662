
.container {
    max-width: 100%;
    width: 1440px;
    margin-inline: auto;
    padding: 0 80px;
}
@media screen and (max-width: 768px) {
    .container {
        padding: 16px
    }
}
.wrap-launch{
    background: #000000;
    font-family: "Radio Canada", sans-serif;
}
.container-landing-top{
    background: url('./img/bg-invest-full.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 800px;
}

.logo-invest img{
    width: 120px;
    height: 120px;
}

.wrap-text-inves {
    width: 60%;
    font-family: "Radio Canada", sans-serif;
    text-align: start;
    font-size: 82px;
    font-weight: 600;
}
.title1-inves{
    -webkit-text-fill-color: #0000;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    background: -webkit-linear-gradient(left, #fff, #5394ff, #fff);
    -webkit-background-clip: text;
}

.title2-inves{
    color: #00EFFF;
}

.break-inves {
    display: none;
}
.dec-invest{
    font-size: 20px;
    color: #ffffff;
    padding: 30px 0 50px 0;
    text-align: start;
    line-height: 24px;
    font-family: "Radio Canada", sans-serif;
    width: 75%;
}

.list-coin img {
    width: 347px;
    height: 135px;
}

.btn-why {
    z-index: 2;
}
.btn-launch{
    background: rgb(0,188,212);
    background: radial-gradient(circle, rgba(0,188,212,1) 0%, rgba(0,71,255,1) 100%);
    cursor: pointer;
    border-radius: 12px;
    height: 48px;
    width: 180px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #00efff;
    border-top: #fff;
    -webkit-box-shadow: inset 0px 6px 9px -4px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 6px 9px -4px rgba(255,255,255,1);
    box-shadow: inset 0px 6px 9px -4px rgba(255,255,255,1);
}
.btn-launch img{
    display: block;
    height: 20px;
    width: 20px;
}
.wrap-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.icon-why img{
    width: 110px;
    height: 110px;
    z-index: 2;
    position: relative;
}
.title-why{
    z-index: 2;
    position: relative;
    // background: linear-gradient(to right, #DDF3FF 0%, #5394FF 100%); 
    // background-clip: text;
    // color: transparent;
    font-size: 64px;
    font-weight: bold;
    padding-bottom: 15px;

    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    background: -webkit-linear-gradient(left, #fff, #5394ff, #fff);
    -webkit-background-clip: text;
}
.dec-why{
    color: #ffffffa6;
    font-size: 20px;
    z-index: 2;
    font-family: "Radio Canada", sans-serif;
    width: 75%;
}
.wrap-why{
    background: #000000;
}
.top-why {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.w-name-item{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: start;
    padding-top: 25px;
    padding-bottom: 15px;
}
.w-dec-item{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    padding-bottom: 25px;
}
.w-img-item img{
    max-height: 280px;
    width: 100%;
    height: 100%;
}
.item-why:hover{
    // border: 1px solid #54FFFF;
    box-shadow: inset 0 0 16px 0 #54FFFF;
    cursor: pointer;
}
.item-why {
    width: calc(4 / 12 * 100% - 25px);
    background: #021233;
    background: radial-gradient(circle at 100% 100%, #021233 0, #021233 23px, transparent 23px) 0% 0%/24px 24px no-repeat,
            radial-gradient(circle at 0 100%, #021233 0, #021233 23px, transparent 23px) 100% 0%/24px 24px no-repeat,
            radial-gradient(circle at 100% 0, #021233 0, #021233 23px, transparent 23px) 0% 100%/24px 24px no-repeat,
            radial-gradient(circle at 0 0, #021233 0, #021233 23px, transparent 23px) 100% 100%/24px 24px no-repeat,
            linear-gradient(#021233, #021233) 50% 50%/calc(100% - 2px) calc(100% - 48px) no-repeat,
            linear-gradient(#021233, #021233) 50% 50%/calc(100% - 48px) calc(100% - 2px) no-repeat,
            linear-gradient(103deg, #01eeff 0%, transparent 14%, rgba(68,132,255,0.39) 100%);
    border-radius: 24px;
    padding: 13px;
    box-sizing: border-box;
    transition: 0.3s ease;
    z-index: 2;
}
.list-why {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;
    padding-top: 65px;
    font-family: "Radio Canada", sans-serif;
}
.asb1{
    width: 300px;
    height: 750px;
    position: absolute;
    top: -80%;
    z-index: 1;
}
.asb2{
    width: 433px;
    height: 433px;
    position: absolute;
    top: 15%;
    z-index: 1;
}
.asb-img {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrap-offer{
    background: #000000;
}
.title-offer {
    // background: linear-gradient(to right, #DDF3FF 0%, #5394FF 100%); 
    // background-clip: text;
    // color: transparent;
    font-size: 64px;
    font-weight: bold;
    padding-bottom: 15px;
    text-align: center;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    background: -webkit-linear-gradient(left, #fff, #5394ff, #fff);
    -webkit-background-clip: text;
}
.dec-offer{
    color: #ffffffa6;
    font-size: 20px;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    width: 75%;
}
.icon-offer img{
    width: 80px;
    height: 80px;
}
// .img-offer{
//     position: relative;
//     z-index: 1;
// }
.op-offer{
    height: 260px;
    width: auto;
}
.img-offer {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content-offer-top {
    // position: absolute;
    // top: 45%;
    // left: 50%;
    // z-index: 2;
    // transform: translateX(-50%);
    // width: 100%;
    margin-top: -90px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.icon-offer{
    padding-bottom: 15px;
    text-align: center;
}
.offer-list1 {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 24px;
}
.offer-item-big:hover{
    cursor: pointer;
     box-shadow: inset 0 0 16px 0 #54FFFF;
}
.offer-item-small:hover{
     cursor: pointer;
     box-shadow: inset 0 0 16px 0 #54FFFF;
}
.offer-item-big {
    width: calc(60% - 12px);
    height: 473px;
    text-align: start;
    position: relative;
    background: radial-gradient(circle at 100% 100%, #030b1b 0, #030b1b 23px, transparent 23px) 0% 0%/24px 24px no-repeat,
            radial-gradient(circle at 0 100%, #030b1b 0, #030b1b 23px, transparent 23px) 100% 0%/24px 24px no-repeat,
            radial-gradient(circle at 100% 0, #030b1b 0, #030b1b 23px, transparent 23px) 0% 100%/24px 24px no-repeat,
            radial-gradient(circle at 0 0, #030b1b 0, #030b1b 23px, transparent 23px) 100% 100%/24px 24px no-repeat,
            linear-gradient(#030b1b, #030b1b) 50% 50%/calc(100% - 2px) calc(100% - 48px) no-repeat,
            linear-gradient(#030b1b, #030b1b) 50% 50%/calc(100% - 48px) calc(100% - 2px) no-repeat,
            linear-gradient(90deg, #01eeff 0%, transparent 54%, rgba(68,132,255,0.39) 100%);
    border-radius: 24px;
    box-sizing: border-box;
    transition: 0.3s ease;
}
.offer-item-small {
    width: calc(40% - 12px);
    background: radial-gradient(circle at 100% 100%, #030b1b 0, #030b1b 23px, transparent 23px) 0% 0%/24px 24px no-repeat,
            radial-gradient(circle at 0 100%, #030b1b 0, #030b1b 23px, transparent 23px) 100% 0%/24px 24px no-repeat,
            radial-gradient(circle at 100% 0, #030b1b 0, #030b1b 23px, transparent 23px) 0% 100%/24px 24px no-repeat,
            radial-gradient(circle at 0 0, #030b1b 0, #030b1b 23px, transparent 23px) 100% 100%/24px 24px no-repeat,
            linear-gradient(#030b1b, #030b1b) 50% 50%/calc(100% - 2px) calc(100% - 48px) no-repeat,
            linear-gradient(#030b1b, #030b1b) 50% 50%/calc(100% - 48px) calc(100% - 2px) no-repeat,
            linear-gradient(90deg, #01eeff 0%, transparent 54%, rgba(68,132,255,0.39) 100%);
    border-radius: 24px;
    box-sizing: border-box;
    height: 473px;
    text-align: start;
}
.offer-item-logo img{
    position: absolute;
    max-width: 50%;
    width: auto;
    max-height: 345px;
    z-index: 2;
}
.offer-item-logo1 img{
    max-width: 300px;
    max-height: 300px;
}
.container-offer {
   margin: 0 auto;
    max-width: 100%;
    padding: 10px;
    width: 1280px;
}
.offer-item-name {
    padding: 23px 0 30px 30px;
    font-size: 32px;
    font-weight: 500;
    color: #fff;
}
.offer-item-dec {
    padding-left: 30px;
    font-size: 16px;
    color: #CDD5DF;
}
.offer-item-small .offer-item-name{
    padding-top: 10px;
    padding-bottom: 25px;
}
.offer-item-logo1 {
    padding-left: 30px;
}
.offer-item-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
}
.offer-item-small.new{
display: flex;
    flex-direction: column;
    justify-content: center;
}

.flow-top{
    // background: url('./img/bg-flow.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: top;
    padding-top: 30px;
    position: relative;
}
.img-bg-flo img{
    position: absolute;
    width: auto;
    top: -75%;
    transform: translateX(-50%);
    left: 50%;
   
}
.num-how {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flow-body{
    position: relative;
    z-index: 9;
    margin: 0 auto;
    max-width: 100%;
}
.icon-flow img{
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.icon-flow{
    padding-bottom: 15px;
    position: relative;
    z-index: 2;
}
.flow-title {
    font-family: "Radio Canada", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 15px;
}
.flow-dec{
    font-family: "Radio Canada", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #CDD5DF;
}
.flow-button {
    padding-top: 30px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.flow-button:hover span{
    cursor: pointer;
    color: rgb(5 141 150);
}

.item-flow.cus{
    flex-direction: row-reverse;
}
.cus .item-flow-right{
    justify-content: flex-start;
}
.item-flow {
    background: rgba(8, 92, 252, 0.08);
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    border-radius: 24px;
    border: 1px solid rgba(68, 132, 255, 0.2);
    margin-bottom: 24px;
    gap: 24px;
    align-items: center;
    overflow: hidden;
}
.content-slide .ant-carousel .slick-prev{
    color: #fff;
    background: rgb(2, 41, 103);
    border-radius: 10px !important;
    width: 30px;
    height: 26px;
    opacity: 1 !important;
}
.content-slide .ant-carousel .slick-prev::after{
    top: calc(50% - 6px) !important;
    inset-inline-start: 10px;
}
.content-slide .ant-carousel .slick-next{
    color: #fff;
    background: rgb(2, 41, 103);
    border-radius: 10px !important;
    width: 30px;
    height: 26px;
    opacity: 1 !important;
}
.content-slide .ant-carousel .slick-next::after{
    top: calc(50% - 6px) !important;
    inset-inline-start: 8px;
}

.item-flow:hover{
    cursor: pointer; 
    // border: 1px solid rgba(68, 132, 255, 1);
    box-shadow: inset 0 0 16px 0 #5482ff;
    transition: 0.3s ease;

}
.flow-num img{
    max-width: 203px;
    max-height: 145px;
}
.item-flow-right img{
    width: 100%;
   max-width: 604px;
    max-height: 394px;
    border-radius: 16px;
    border-top: 2px solid #5394FF;
    transform: scale(1);
    transition: .3s ease;
}
.item-flow-right img:hover{
    transform: scale(1.03);
}
.item-flow-left{
    width:calc(50% - 12px);
    text-align: start;
    padding: 20px;
}
.item-flow-right {
    width:calc(50% - 12px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flow-main {
    margin-top: 130px;
    background: #000000;
}
.flow-top .title-offer{
    padding-bottom: 60px;
    position: relative;
    z-index: 2;

}
.dec-com{
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 239, 255, 1);
}
.flow-button span{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: rgba(0, 239, 255, 1);
}
.flow-button img{
    width: 24px;
    height: 24px;
    display: block;
}
.icon-com img{
    width: 75px;
    height: 75px;
}
.icon-com{
    padding-bottom: 35px;
}
.name-com{
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 10px;
}
.fl-com{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
}
.item-com {
    background: rgba(8, 92, 252, 0.08);
    padding: 50px;
    border-radius: 24px;
    border: 1px solid rgba(68, 132, 255, 0.2);
}
.item-com:hover {
    cursor: pointer;
    background: linear-gradient(0deg,#0047ff,#0000);
    border: none;
  
}
.container-com {
    background: #000;
}
.list-com {
    display: flex;
    flex-wrap: wrap;
    padding: 0 150px;
}
.wrap-com {
    width: 25%;
    padding: 10px;
}
.list-flow {
    padding-bottom: 60px;
}

.com-top .title-offer{
    padding-top: 20px;
    padding-bottom: 40px;
}
.btn-launch-new:hover{
    background: #000;
}
// css new
.sol-item img{
    width: 32px;
    height: 32px;
}
.sol-item:hover{
    cursor: pointer;
}
.img-invest img{
    width: 400px;
    height: 400px;
    position: absolute;
    top: 50px;
    right: 10px;
}
.img-meyfi{
    display: none;
}
.img-meyfi img{
    width: 210px;
    height: 210px;
    position: absolute;
    top: 460px;
    right: 100px;
}
.wap-text-invest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 50px;
}
.investment-landing {
    height: 100%;
    position: relative;
}
.list-coin {
    display: flex;
    gap: 20px;
    align-items: center;
    position: relative;
    z-index: 999;
}
.num-how1{
    width: 46px;
    height: 46px;
    display: block;
}
.num-how2{
    width: 75px;
    display: block;
}

.title-item-how {
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #fff;
    text-align: left;
    padding: 30px 0 15px 0;
}
.dec-item-how {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(205, 213, 223, 1);
    text-align: left;
    padding-bottom: 50px;
}
.list-how {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    gap: 20px;
}
.item-how {
    border: 1px solid rgba(68, 132, 255, 0.2);
    border-radius: 24px;
    padding: 12px;
    height: 235px;
     transition: 0.3s ease;
}
.item-how:hover{
    box-shadow: inset 0 0 16px 0 #54FFFF;
    cursor: pointer;    
}
.item-lan-slide{
    height: 410px;
    width: 100%;
    border-radius: 8px;
}
.container-slide {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 100px;
    width: 100%;
    position: relative;
    z-index: 9;
}
.container-launch{
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;
}
.content-launch{
    background: url('./img/bg-launch.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    flex-wrap: wrap;
    border-radius: 12px;
}
.launch-right img{
    width: 450px;
    height: 450px;
}
.launch-right {
    height: 100%;
}
.launch-left {
    width: 50%;
}
.launch-right{
    width: 50%;
}
.title-launch {
    font-size: 64px;
    font-weight: bold;
    padding-bottom: 15px;
    text-align: center;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    background: -webkit-linear-gradient(left, #fff, #5394ff, #fff);
    -webkit-background-clip: text;
    text-align: left;
}
.dec-launch{
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(255, 255, 255, 0.64);
    text-align: left;
    padding-top: 16px;
    padding-bottom: 36px;

}
.btn-launch-new{
    background: rgb(0,188,212);
    background: radial-gradient(circle, rgba(0,188,212,1) 0%, rgba(0,71,255,1) 100%);
    cursor: pointer;
    border-radius: 8px;
    height: 40px;
    width: 200px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #00efff;
 
}
.launch-new{
    text-align: left;
}


 @keyframes animationgradienttitle {
  0% {
      background-position: 0 1600px;
  }
  100% {
      background-position: 1600px 0;
  }
}



@media screen and (max-width: 768px) {
    .container-why, .container-offer  {
    padding: 10px;
}
    .img-meyfi{
        display: none;
    }
    .wap-text-invest{
        width: 100%;
    }

    .break-inves {
        display: block;
    }

    .wrap-text-inves {
        text-align: center;
        font-size: 32px;
        line-height: 38.4px;
        width: 100%
    }

    .dec-invest{
        font-size: 14px;
        padding: 15px 0 25px 0;
        text-align: center;
        width: 100%
    }
    .img-invest img{
        width: 300px;
        height: 300px;
    }
    .container-landing-top{
        background-position: left;
        height: 800px;
        padding-bottom: 50px;
        background: url('./img/bg-invest3-mo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
    .list-coin{
        width: 100%;
        justify-content: center;
    }
    .title-why{
        font-size: 32px;
        line-height: 38px;
    }
    .dec-why{
        font-size: 14px;
        width: 100%
    }
    .list-why{
        padding-top: 50px;
    }
    .item-why{
        width: 100%;
    }
    .op-offer {
    height: auto;
    width: 100%;
    }
    .content-offer-top {
    margin-top: -40px;
}
.title-offer{
    font-size: 32px;
    line-height: 38px;
}
.dec-offer{
    font-size: 14px;
    width: 100%
}
.item-flow{
    padding: 15px;
    flex-direction: column-reverse;
}
.item-flow-left{
    width: 100%;
    padding: 0;
}
.item-flow-right{
    width: 100%;
    padding: 0;
}
.flow-title{
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
}
    .flow-dec{
        font-size: 14px;
        line-height: 16.8px;
    }
    .item-how {
        height: 184px
    }
.list-flow {
    padding-bottom: 10px;
}
.flow-top .title-offer{
    padding-bottom: 30px;
}
    .list-how {
        grid-template-columns: repeat(1, minmax(0, 1fr));

    }
.wrap-item-how{
    width: 100%;
}
.launch-left{
    width: 100%;
}
.launch-right{
    width: 100%;
}
.content-launch{
    height: auto;
    padding: 20px;

}
.title-launch{
    font-size: 26px;
    width: 100%;
}
.dec-launch{
padding-top: 10px;
    padding-bottom: 25px;
}
.launch-right img {
    width: 300px;
    height: 300px;
}
.launch-right{
    padding-top: 30px;
}
.content-launch{
 background: url('./img/bg-launch-mo.png');
 background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    border-radius: 12px;
}
}

@media screen and (max-width: 1024px) {
.wrap-text-inves{
    line-height: normal;
}
.img-bg-flo img{
    top: -50%;
}
}