.porfolio-wrap {
    margin-bottom: 20px;

    .kyc-user {
        border: 1px solid #3B3F45;
        padding: 15px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-row {
            .title {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: left;
                color: #00BCD4;
                margin-bottom: 5px;
            }

            .desc {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: left;
                color: #ffffff75;
            }
        }

        .center-row {
            width: 100%;

            .time-line-kyc {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;

                .item {
                    display: flex;
                    align-items: center;
                    margin-right: 80px;
                    position: relative;

                    &::before {
                        content: "";
                        background: #ffffffa9;
                        width: 50px;
                        height: 1px;
                        position: absolute;
                        right: -70px;
                        top: 13px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &:last-child:before {
                        display: none;
                    }

                    &.active {
                        .text {
                            color: #00BCD4;
                        }
                    }

                    .box-icon {
                        margin-right: 5px;

                        img {
                            width: 30px;
                            display: block;
                        }
                    }

                    .text {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: center;
                        color: #ffffff7f;
                    }
                }
            }
        }

        .right-row {
            .button-kyc {
                .start-kyc {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: center;
                    color: #2B79F7;
                    background: #2B79F71A;
                    border-radius: 8px;
                    padding: 10px 15px;
                    border: 0px solid transparent;
                    cursor: pointer;
                }
            }
        }
    }
}

.profit-user {
    display: flex;
    flex-wrap: wrap;

    .content-left {
        width: 30%;

        .user-create {
            border: 1px solid #3B3F45;
            padding: 15px;
            border-radius: 12px;
            text-align: center;
            height: 100%;
            position: relative;

            .box-img-edit {
                position: absolute;
                right: 15px;
                top: 15px;
                cursor: pointer;

                img {
                    width: 20px;
                    display: block;
                }
            }

            .box-img {
                margin-bottom: 10px;

                img {
                    width: 60px;
                    display: block;
                    margin: 0 auto 10px;
                    border-radius: 99px;
                    height: 60px;
                    object-fit: cover;
                }
            }

            .name-user {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Radio Canada;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #fff;
                margin-bottom: 15px;

                img {
                    width: 20px;
                    display: block;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            .contributor-text {
                border: 1px solid transparent;
                background: linear-gradient(#000, #000) padding-box,
                    linear-gradient(260.33deg, #67CC65 -13.37%, #00BCD4 109.53%) border-box;
                border-radius: 8px;
                width: fit-content;
                margin: 0 auto;
                padding: 7px 7px;
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;
                color: #67CC65;

                img {
                    width: 20px;
                    display: block;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }

            .button-vesting-user {
                margin-top: 15px;

                .btn-vesting-user {
                    width: 100%;
                    height: 40px;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: center;
                    color: #2B79F7;
                    background: #2B79F733;
                    border-radius: 8px;
                    border: 0px solid transparent;
                    cursor: pointer;
                }
            }

            .details-user {
                padding-top: 15px;
                margin-top: 15px;
                border-top: 1px solid #ffffff43;

                .txt {
                    font-family: Radio Canada;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 16.8px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 20px;
                }

                .list-repage {
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        cursor: pointer;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            width: 100%;
                        }

                        .box-tier-remask {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            background: #3B3F4566;
                            padding: 12px;
                            border-radius: 8px;

                            .l {
                                font-size: 16px;
                                color: #fff;
                            }

                            .r {
                                font-size: 18px;
                                color: #fff;
                                font-weight: 600;
                                text-transform: uppercase;

                                img {
                                    width: 30px;
                                }
                            }
                        }
                    }

                    .tier-images {
                        display: flex;
                        align-items: center;
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        color: #fff;

                        img {
                            width: 20px;
                            display: block;
                            margin-right: 5px;
                        }
                    }

                    .l {
                        display: flex;
                        align-items: center;
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #ffffff7a;

                        img {
                            width: 16px;
                            display: block;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }

                    .r {
                        display: flex;
                        align-items: center;
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #fff;

                        .veryfi-span {
                            color: green;
                        }

                        .unveryfi-span {
                            color: #688CB1;
                        }

                        img {
                            width: 16px;
                            display: block;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .content-right {
        width: 70%;

        .box-chart {
            background: #191b1d;
            margin-left: 20px;
            border: 1px solid #3B3F45;
            padding: 15px;
            border-radius: 12px;

            &.res {
                background: transparent;
            }

            .box-img {
                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}

.guide-user-pro {
    padding-left: 20px;

    .my-profile-user {
        border: 1px solid #3B3F45;
        padding: 15px;
        border-radius: 12px;

        .title {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.4;
            text-align: left;
            margin-bottom: 15px;
        }

        .box-account {
            background: linear-gradient(260.33deg, #0047FF -13.37%, #00BCD4 109.53%);
            padding: 15px;
            border-radius: 12px;
            margin-bottom: 15px;

            .top-account {
                display: flex;
                align-items: center;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;
                border-bottom: 1px solid #f4f4f538;
                padding-bottom: 10px;
                margin-bottom: 10px;

                span {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.4;
                    text-align: left;
                    color: #ffffff97;
                }

                img {
                    width: 16px;
                    display: block;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            .bottom-account {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .balance {
                    font-family: Radio Canada;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 1.4;
                    text-align: left;
                    color: #fff;

                    span {
                        display: block;
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.4;
                        text-align: left;
                        color: #ffffff97;
                        margin-top: 7px;
                    }
                }

                .box-img {
                    img {
                        width: 50px;
                        display: block;
                    }
                }
            }
        }
    }
}

.list-token-by-user {
    .item-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .txt {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff88;
        }
    }

    .item-bottom {
        .token-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-radius: 8px;
            transition: 0.3s ease;

            &:last-child {
                margin-bottom: 0;
            }

            .name-token {
                display: flex;
                align-items: center;

                .logo-token {
                    width: 40px;

                    .quote-symbol {
                        position: relative;

                        .big {
                            width: 40px;
                            display: block;
                            border-radius: 99px;
                        }

                        .small {
                            width: 16px;
                            display: block;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }

                .symbol {
                    margin-left: 10px;
                    text-align: left;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: left;
                    color: #fff;

                    .balance {
                        font-family: Radio Canada;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.4px;
                        text-align: left;
                        color: #ffffff7b;
                        margin-top: 5px;

                        .green {
                            font-family: Radio Canada;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14.4px;
                            text-align: left;
                            color: #00D415;
                            margin-left: 5px;
                        }

                        .red {
                            font-family: Radio Canada;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14.4px;
                            text-align: left;
                            color: red;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .balance-token {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                color: #fff;
                text-align: right;

                span {
                    font-family: Radio Canada;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.4px;
                    color: #ffffff7b;
                    margin-top: 5px;
                    display: block;
                }
            }
        }
    }
}

.list-tabs-user {
    margin-top: 20px;

    .tabs-list {
        .ant-tabs-nav-wrap {
            border-bottom: 1px solid #ffffff22;
        }

        .ant-tabs-nav-list {
            width: 550px;
        }

        .ant-tabs-tab {
            .title-tabs {
                color: #ffffff85;

                svg {
                    fill: #ffffff85;

                    path {
                        fill: #ffffff85;
                    }
                }
            }
        }

        .ant-tabs-tab-active {
            .title-tabs {
                color: #fff !important;

                svg {
                    fill: #fff !important;
                    fill-opacity: 1;

                    path {
                        fill: #fff !important;
                        fill-opacity: 1;
                    }
                }
            }
        }
    }
}

.guide-item {
    .bottom-item {
        .commercial-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            .item {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    display: block;
                    margin-right: 5px;
                }

                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 14.4px;
                text-align: left;
                color: #fff;

                span {
                    color: #ffffff7a;
                    margin-right: 3px;
                }
            }
        }

        .property-price {
            margin-top: 15px;
            padding: 15px;
            border-radius: 12px;
            border: 1px solid #3B3F45;
            background: #3B3F4566;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #ffffff87;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.2px;
                text-align: left;
                color: #fff;
            }
        }

        .list-assigment {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;

                .icon {
                    width: 26px;

                    img {
                        width: 26px;
                        display: block;
                    }
                }

                .text {
                    width: calc(100% - 36px);

                    .txt-top {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14.4px;
                        text-align: left;
                        color: #ffffff7b;
                        margin-bottom: 5px;
                    }

                    .txt-bot {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14.4px;
                        text-align: left;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.list-filter-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 5px;

    &.res {
        .row-right {
            width: 100%;

            .status-filter {
                margin-left: auto;
            }
        }
    }

    .row-left {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: #fff;

        &.res-left {
            border: 1px solid #3B3F45;
            padding: 2px;
            border-radius: 8px;
        }

        .filter-grow {
            position: relative;
            top: 0;
        }
    }

    .row-right {
        display: flex;
        align-items: center;

        &.ress {
            margin-left: auto;
        }

        .row-search {
            position: relative;

            input {
                border: 1px solid #3B3F4533;
                width: 300px;
                height: 40px;
                background: #3B3F4566;
                border-radius: 8px;
                padding: 10px 10px 10px 40px;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #fff;
            }

            img {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .status-filter {
            margin-left: 10px;

            .btn-status {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: center;
                width: 90px;
                height: 40px;
                color: #fff;
                background: #3B3F4566;
                border: 1px solid #3B3F4533;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

.main-table-row {
    border: 1px solid #3B3F45;
    padding: 15px 15px 0 15px;
    border-radius: 8px;

    .main-table {
        width: 100%;

        tr {
            th {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #ffffffaa;
                padding: 15px;
                background: #3B3F4566;

                &:first-child {
                    border-radius: 8px 0 0 8px;
                }

                &:last-child {
                    border-radius: 0 8px 8px 0;
                }
            }

            td {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #fff;
                padding: 15px;

                .color-apy {
                    color: #00EFFF;
                }

                .main-color-table {
                    color: #ffffff7d;
                }

                .main-color-amount {
                    color: #68FF80;
                }

                .main-color-negative {
                    color: #cc0000;
                }

                .name-pro {
                    display: flex;
                    align-items: center;

                    img {
                        width: 30px;
                        display: block;
                        margin-right: 5px;
                    }
                }

                .row-link {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        display: block;
                        margin-left: 5px;
                    }
                }

                .status-success {
                    font-family: Radio Canada;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.4px;
                    text-align: right;
                    color: #00D415;
                    background: #00D4151A;
                    border-radius: 8px;
                    padding: 7px 10px;
                    width: fit-content;
                }

                .status-pending {
                    font-family: Radio Canada;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.4px;
                    text-align: right;
                    color: #DEC43B;
                    background: #DEC43B1A;
                    border-radius: 8px;
                    padding: 7px 10px;
                    width: fit-content;
                }

                .status-fail {
                    font-family: Radio Canada;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.4px;
                    text-align: right;
                    color: #B43C3C;
                    background: #B43C3C1A;
                    border-radius: 8px;
                    padding: 7px 10px;
                    width: fit-content;
                }

                .btn-action-table {
                    width: 60px;
                    height: 36px;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    color: #2B79F7;
                    background: #2B79F71A;
                    border-radius: 8px;
                    padding: 10px;
                    border: 0px solid transparent;
                    text-align: center;

                    &.dis {
                        color: #ffffffa8;
                        background: #ffffff11;
                    }
                }
            }
        }
    }
}

.group-edit-setting {
    .row-back-profile {
        a {
            display: flex;
            align-items: center;
            justify-content: left;
            font-family: Radio Canada;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            text-decoration: none;
            color: #fff;
            margin-bottom: 15px;

            img {
                width: 24px;
                display: block;
                margin-right: 7px;
            }
        }

        .desc {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #FFFFFF99;
            margin-bottom: 15px;
        }
    }
}

.form-group-setting {
    width: 80%;

    .colum.w-7 {
        padding: 10px;
    }

    .content-between {
        padding: 10px;

        .text-row {
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            color: #fff;
            margin-bottom: 10px;
        }

        .desc-row {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #FFFFFF99;
        }
    }

    .content-form {
        padding: 15px;
        border: 1px solid #3B3F45;
        border-radius: 12px;
        cursor: pointer;

        .guide-avatar {
            display: flex;
            align-items: center;

            .left-av {
                .ant-upload-list {
                    display: flex;
                    cursor: pointer;
                }

                .ant-upload-list-item {
                    &.ant-upload-list-item-error {
                        border-color: #00EFFF;
                    }
                }

                button {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: center;
                    color: #fff;

                    svg {
                        text-align: center;
                    }
                }

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 99px;
                    display: block;
                }
            }

            .right-av {
                margin-left: 30px;

                .box-img {
                    margin-bottom: 10px;
                    cursor: pointer;

                    img {
                        width: 72px;
                        display: block;
                    }
                }

                .desc-pp {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: left;
                    color: #FFFFFF99;
                }
            }
        }

        .group-input {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .row-input {
                width: calc(50% - 5px);
            }
        }

        .row-input {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .txt {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #ffffff7d;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                height: 48px;
                padding: 0 15px;
                background: #191B1D;
                box-shadow: 0px 2px 6px 0px #0000001A inset;
                border: 1px solid transparent;
                transition: 0.3s ease;
                border-radius: 6px;
                color: #fff;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    border: 1px solid #00EFFF;
                }

                &:focus {
                    outline: none;
                    border: 1px solid #00EFFF !important;
                }
            }

            .textarea-input {
                .ant-upload-drag {
                    border: 1px dashed #ffffff75;
                }

                .ant-upload-text {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    color: #fff;
                }

                .ant-upload-hint {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    color: #ffffff75;
                }
            }
        }
    }
}

.group-button-save {
    width: calc(7 / 12* 100% - 10px);
    margin-left: auto;
    text-align: left;

    .btn-save-edit {
        width: 30%;
        height: 40px;
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: center;
        background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box, linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
        box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
        border-radius: 8px;
        border: 0px solid transparent;
        color: #fff;
        cursor: pointer;

        &.dis {
            background: rgb(22 24 26);
            color: #ffffff91;
        }
    }
}

.w-5 {
    width: calc(5 / 12 * 100%);
}

.w-7 {
    width: calc(7 / 12 * 100%);
}

.colum-group {
    margin-bottom: 15px;

    &.res {
        margin-bottom: 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.box-no-data {
    img {
        width: 100px;
        display: block;
        margin: 30px auto;
    }
}

.verify-container {
    width: 100% !important;
}

.kyc-status-party {
    display: flex;
    align-items: center;

    .box-img {
        img {
            width: 42px;
            display: block;
        }
    }

    .text-finger {
        margin-left: 10px;
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        text-align: left;
        cursor: pointer;

        span {
            margin-top: 5px;
            display: block;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 23.52px;
            text-align: left;
            color: #2B79F7A1;
            text-decoration: underline;
        }
    }
}

.tier-infomation-row {
    background: #000;
    border-radius: 16px;

    .infomation-top {
        padding: 30px;

        .images-tier-top {
            .box-img {
                position: relative;
                margin-bottom: 30px;

                .left-arrow {
                    position: absolute;
                    left: 20%;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;

                    img {
                        width: 20px;
                        display: block;
                    }
                }

                .right-arrow {
                    position: absolute;
                    right: 20%;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;

                    img {
                        width: 20px;
                        display: block;
                    }
                }

                img {
                    width: 120px;
                    display: block;
                    margin: 0 auto;
                }
            }

            .name-tier {
                font-family: Radio Canada;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.01em;
                text-align: center;
                color: #fff;
                margin-top: 20px;
            }
        }

        .images-tier-bot {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;

            .item {
                padding: 10px;
                border-radius: 8px;
                width: auto;
                border: 1px solid transparent;
                opacity: 0.5;

                &.active {
                    border: 1px solid #3A71FF;
                    background: #3A71FF33;
                    opacity: 1;
                }

                img {
                    width: 60px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    .box-current-tier-page {
        margin-top: 20px;
        background: #2B79F71A;
        padding: 15px;
        border-radius: 8px;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            .text-left {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                text-align: center;
                color: #ffffff78;

                span {
                    color: #fff;
                    font-weight: 600;
                    margin-left: 5px;
                }
            }

            .text-right {
                font-family: Radio Canada;
                font-size: 14px;
                line-height: 16.8px;
                text-align: center;
                color: #fff;
                font-weight: 600;
            }
        }

        .bottom {
            .ant-progress-text {
                display: none;
            }

            .ant-progress-inner {
                background: #FFFFFF1A;
            }

            .ant-progress-bg {
                background: #00EFFF;
                height: 8px !important;
            }

            .bottom-number-stake {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: center;
                    color: #ffffff78;
                }

                .right {
                    font-family: Radio Canada;
                    font-size: 14px;
                    line-height: 16.8px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;

                    span {
                        color: #01E4FF;
                    }
                }
            }
        }
    }

    .group-button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-info-action {
            background: #085CFC1A;
            width: 49%;
            height: 45px;
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: center;
            color: #fff;
            border: 0px solid transparent;
            border-radius: 8px;
            cursor: pointer;

            a {
                color: #fff;
            }

            &.res {
                background: #085CFC;
                box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
            }
        }
    }
}

.time-clock {
    display: flex;
    align-items: center;
    background: #2B79F7;
    border-radius: 5px;
    padding: 5px;
    color: #fff;

    span {
        color: #fff !important;
    }

    img {
        width: 13px;
        display: block;
        margin-right: 5px;
        margin-top: -2px;
    }
}

.flex-chart {
    .flex-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .txt-left {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #fff;
        }

        .txt-right {
            display: flex;
            align-items: center;

            .item {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                text-align: left;
                color: #ffffff7c;
                margin-left: 15px;
                cursor: pointer;

                &.active {
                    color: #fff;
                }
            }
        }
    }

    .flex-bottom {
        padding: 10px 10px 10px 10px;

        .box-line-js {
            position: relative;
            overflow: hidden;
            z-index: 2;

            img {
                position: absolute;
                top: 50%;
                left: 52%;
                width: 95%;
                height: 90%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }

            canvas {
                width: 100% !important;
                position: relative;
                z-index: 2;
            }
        }
    }
}

td.nodata {
    text-align: center !important;
}

.row-link:hover {
    color: #0047FF;
}

.main-title-tabs {
    margin-bottom: 20px;

    .title-tabs {
        display: flex;
        align-items: center;
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;

        svg {
            margin-right: 5px;
        }
    }
}

.note-line {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        margin-right: 30px;

        .icon {
            img {
                width: 10px;
                display: block;
            }
        }

        .name-line {
            font-family: Radio Canada;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            text-align: left;
            color: #fff;
            margin-left: 10px;
        }
    }
}

.my-rank-by-user {
    .go-back {
        display: flex;
        align-items: center;
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #fff;

        img {
            width: 22px;
            display: block;
            margin-right: 5px;
        }
    }

    .top-rank-by-user {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-between;

        .left-contants {
            width: calc(3.5 / 12 * 100%);

            .my-rank {
                display: flex;
                border: 1px solid #2B79F7;
                background: #191B1D;
                padding: 15px;
                border-radius: 12px;
                align-items: center;
                height: 77.8px;

                .icon-rank {
                    img {
                        width: 46px;
                        display: block;
                    }
                }

                .text-rank {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: left;
                    color: #707A8A;
                    margin-left: 10px;

                    span {
                        font-family: Radio Canada;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                        text-align: left;
                        color: #fff;
                        margin-top: 5px;
                        display: block;
                    }
                }
            }
        }

        .right-contants {
            width: calc(8.5 / 12 * 100% - 20px);

            .stake-user-rank {
                display: flex;
                border: 1px solid transparent;
                background: #191B1D;
                padding: 15px;
                border-radius: 12px;
                align-items: center;
                justify-content: space-between;
                height: 77.8px;

                .row-item {
                    .text-rank {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.8px;
                        text-align: left;
                        color: #707A8A;
                        margin-left: 10px;

                        span {
                            font-family: Radio Canada;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 24px;
                            text-align: left;
                            color: #fff;
                            margin-top: 5px;
                            display: block;
                        }
                    }

                    .stake-more-btn {
                        background: #085CFC;
                        box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
                        width: 135px;
                        height: 40px;
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19.2px;
                        text-align: center;
                        color: #fff;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    .bottom-by-user-ranked {
        margin-top: 20px;
        border: 1px solid #3B3F45;
        padding: 20px;
        border-radius: 12px;

        .list-images-tier {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;
            justify-content: flex-end;

            .item-control-images {
                width: calc(2 / 12 * 100%);

                .icon-tier {
                    margin-bottom: 15px;

                    img {
                        width: 92px;
                        display: block;
                        margin: 0 auto;
                    }
                }

                .name-core {
                    font-family: Radio Canada;
                    font-size: 16.96px;
                    font-weight: 500;
                    line-height: 20.35px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 24px;
                }
            }
        }

        .list-item-by-rank {
            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            transition: 0.3s ease;
            border: 1px solid transparent;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                border: 1px solid #00EFFF;
                box-shadow: 0px 0px 12px 0px #2B79F7 inset;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .item-control {
                width: calc(2 / 12 * 100%);
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: center;
                color: #FFFFFF99;

                .text-control {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: left;
                    color: #fff;
                }
            }
        }
    }

    .bottom-rank-by-user {
        margin-top: 20px;
        border: 1px solid #3B3F45;
        padding: 20px;
        border-radius: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .left-contants {
            width: calc(3.5 / 12 * 100%);
            display: flex;
            flex-direction: column-reverse;

            .box-required-stake {
                background: #3B3F4566;
                border: 1px solid #3B3F454D;
                padding: 20px;
                border-radius: 12px;
                margin-top: auto;

                .txt {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .right-contants {
            width: calc(8.5 / 12 * 100% - 20px);

            .relative-box {
                display: flex;
                flex-wrap: wrap;

                .item-core {
                    width: calc(20%);

                    .icon-tier {
                        margin-bottom: 15px;

                        img {
                            width: 92px;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .name-core {
                        font-family: Radio Canada;
                        font-size: 16.96px;
                        font-weight: 500;
                        line-height: 20.35px;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 24px;
                    }

                    .text {
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-align: center;
                        color: #FFFFFF99;
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.verify-account-email {
    margin-top: 20px;

    .text {
        a {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #2B79F7;

        }
    }
}

.wrap-verify-email {
    .go-back {
        display: flex;
        align-items: center;
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #fff;
        margin-bottom: 25px;

        img {
            width: 22px;
            display: block;
            margin-right: 5px;
        }
    }

    .title {
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        margin-bottom: 5px;
    }

    .desc {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 24.24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #707A8A;

    }
}

.group-verify-box {
    .box-img {
        margin-top: 25px;
        margin-bottom: 25px;

        img {
            width: 70%;
            display: block;
            margin: 0 auto;
        }
    }

    .daily-group-verify {
        .reder-box {
            background: #2B3342;
            border-radius: 12px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-render {
                .top {
                    display: flex;
                    align-items: center;

                    img {
                        width: 20px;
                        display: block;
                        margin-right: 5px;
                    }

                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.2px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #fff;
                }

                .bottom {
                    display: flex;
                    margin-top: 15px;
                    align-items: center;
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 19.2px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #fff;

                    img {
                        width: 20px;
                        display: block;
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }
            }

            .right-render {
                .btn-checked {
                    background: #085CFC;
                    padding: 12px 15px;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    border: 1px solid transparent;
                    border-radius: 12px;

                    img {
                        width: 20px;
                        display: block;
                        margin-right: 5px;
                    }

                    &.non-check {
                        background: transparent;
                        border: 1px solid #FFFFFF33;
                    }
                }
            }
        }
    }
}

.list-send-otp {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #AAB6CD;
        display: flex;
        align-items: center;

        .line-modal {
            margin: 0 10px 0 20px;
            width: 30px;
        }
    }
}

.modal-verify-box {
    padding: 0 30px;

    .box-img-modal {
        margin-top: 20px;

        img {
            width: 65px;
            display: block;
            margin: 0 auto;
        }
    }

    .title-email {
        margin-top: 20px;
        font-family: Radio Canada;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.01em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
    }

    .desc-email {
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #707A8A;
        margin-top: 10px;

        span {
            color: #2B79F7;
            margin-top: 5px;
            display: block;
        }
    }

    .input-modal {
        margin-top: 15px;
        position: relative;

        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
        }

        input {
            width: 100%;
            height: 48px;
            padding: 0 15px;
            background: #191B1D;
            box-shadow: 0px 2px 6px 0px #0000001A inset;
            border: 1px solid transparent;
            transition: 0.3s ease;
            border-radius: 6px;
            color: #fff;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            letter-spacing: 0.01em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                border: 1px solid #00EFFF;
            }
        }
    }

    .button-click {
        text-align: center;
        margin-top: 15px;

        .btn-click-email {
            margin: 0 auto;
            background: #085CFC;
            padding: 12px 25px;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            border-radius: 8px;

            &.dis {
                background: #0b1936;
                cursor: no-drop;
            }
        }
    }
}

.color-green {
    color: #00D415;
}

@media screen and (max-width: 768px) {
    .section-grow {
        padding: 10px;

        .grow-wrap {
            &.wrap-mobile-porfolio {
                flex-direction: column-reverse;

                .wrap-left {
                    width: 100%;

                    .content-left {
                        width: 100%;
                    }

                    .content-right {
                        width: 100%;
                    }

                    .box-chart {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }

                .wrap-right {
                    width: 100%;
                    margin-bottom: 10px;

                    .guide-user-pro {
                        padding-left: 0;
                    }
                }
            }

            .list-tabs-user .tabs-list .ant-tabs-tab {
                margin-right: 20px;

                .title-tabs {
                    font-size: 14px;
                }
            }
        }
    }

    .w-4 {
        width: 100% !important;
    }

    .list-filter-profile {
        flex-wrap: wrap;

        .row-left {
            margin-bottom: 10px;
            font-size: 14px;
        }

        .row-right {
            width: 100%;

            .row-search {
                width: 80%;

                input {
                    width: 100%;
                }
            }
        }
    }

    .mar-b-100-p2p {
        margin-bottom: 30px;
    }

    .footer-app .row-footer {
        border: 0px solid rgba(59, 63, 69, 0.4);
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .section-grow .grow-wrap .wrap-left {
        width: 100%;
    }

    .list-filter-profile .row-left {
        display: none;
    }

    .guide-user-pro {
        display: none;
    }

    .profit-user .content-left {
        width: 50%;
    }

    .profit-user .content-right {
        width: 50%;
    }
}

@media screen and (max-width: 1024px) {
    .row-right {
        width: 100%;

        .row-search {
            width: 100%;

            input {
                width: 100% !important;
            }
        }
    }

    .row-item-mobile {
        background: rgba(59, 63, 69, 0.4);
        border: 1px solid rgba(59, 63, 69, 0.2);
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 15px;

            &:last-child {
                padding-bottom: 0;
            }

            .color-apy {
                color: #00EFFF;
            }

            .main-color-table {
                color: #ffffff7d;
            }

            .main-color-amount {
                color: #68FF80;
            }

            .main-color-negative {
                color: #cc0000;
            }

            .txt-l {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                color: #ffffff7d;
            }

            .status-success {
                font-family: Radio Canada;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: right;
                color: #00D415;
                background: #00D4151A;
                border-radius: 8px;
                padding: 7px 10px;
                width: fit-content;
            }

            .status-pending {
                font-family: Radio Canada;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: right;
                color: #DEC43B;
                background: #DEC43B1A;
                border-radius: 8px;
                padding: 7px 10px;
                width: fit-content;
            }

            .status-fail {
                font-family: Radio Canada;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: right;
                color: #B43C3C;
                background: #B43C3C1A;
                border-radius: 8px;
                padding: 7px 10px;
                width: fit-content;
            }

            .txt-right {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                letter-spacing: 0.01em;
                color: #fff;

                .row-link {
                    display: flex;
                    align-items: center;

                    img {
                        width: 15px;
                        display: block;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .row-mb {
        overflow-y: auto;

        .main-table {
            width: 1000px;
        }
    }

    .w-5 {
        width: 100%;
    }

    .w-7 {
        width: 100%;
    }

    .form-group-setting {
        width: 100%;
    }

    .group-edit-setting .row-back-profile a {
        font-size: 18px;
    }

    .group-edit-setting .row-back-profile .desc {
        font-size: 12px;
    }

    .form-group-setting .content-between .desc-row {
        font-size: 12px;
    }

    .form-group-setting .content-between .text-row {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .form-group-setting .content-form .guide-avatar .right-av .desc-pp {
        font-size: 12px;
    }

    .form-group-setting .content-form .row-input .txt {
        font-size: 13px;
    }

    .my-rank-by-user .top-rank-by-user .left-contants {
        width: 100%;
    }

    .my-rank-by-user .top-rank-by-user .right-contants {
        width: 100%;
    }

    .my-rank-by-user .top-rank-by-user .right-contants .stake-user-rank {
        flex-wrap: wrap;
        height: auto;
    }

    .my-rank-by-user .top-rank-by-user .right-contants .stake-user-rank .row-item {
        width: 100%;
        text-align: left;
        margin-bottom: 15px;
    }

    .my-rank-by-user .top-rank-by-user .left-contants .my-rank {
        margin-bottom: 10px;
    }

    .my-rank-by-user .top-rank-by-user .right-contants .stake-user-rank .row-item:last-child {
        margin-bottom: 0;
    }

    .my-rank-by-user .top-rank-by-user .right-contants .stake-user-rank .row-item .text-rank {
        margin-left: 0;
        font-size: 14px;
    }

    .my-rank-by-user .top-rank-by-user .right-contants .stake-user-rank .row-item .text-rank span {
        font-size: 16px;
    }

    .my-rank-by-user .bottom-by-user-ranked {
        padding: 10px;
        overflow-y: auto;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-images-tier {
        width: 800px;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-item-by-rank {
        padding: 10px;
        width: 800px;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-images-tier .item-control-images .icon-tier img {
        width: 70px;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-images-tier .item-control-images .name-core {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-item-by-rank .item-control .text-control {
        font-size: 14px;
    }

    .my-rank-by-user .bottom-by-user-ranked .list-item-by-rank .item-control {
        font-size: 14px;
    }

    .group-verify-box {
        display: flex;

        .box-img {
            img {
                width: 75px !important;
                height: 100%;
            }
        }
    }

    .group-verify-box .daily-group-verify .reder-box .left-render .top {
        font-size: 14px;
    }

    .group-verify-box .daily-group-verify .reder-box .left-render .bottom {
        font-size: 14px;
    }

    .group-verify-box .daily-group-verify .reder-box .right-render .btn-checked {
        padding: 10px;
        font-size: 14px;
    }

    .wrap-verify-email .title {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .wrap-verify-email .desc {
        font-size: 14px;
    }

    .group-verify-box .daily-group-verify .reder-box .left-render .top img {
        width: 15px;
    }

    .modal-verify-box {
        padding: 0;
    }

    .modal-verify-box .title-email {
        font-size: 16px;
        line-height: 1.4;
    }

    .wrap-verify-email .go-back {
        font-size: 14px;
    }

    .my-rank-by-user .go-back {
        font-size: 14px;
    }

    .res-mobile {
        .row-right {
            display: block !important;

            .row-search {
                width: 100%;
                margin-bottom: 5px;
            }

            .group-his-left {
                .status-filter {
                    width: 50%;

                    .btn-status {
                        width: 100%;
                    }
                }
            }
        }
    }

    .section-grow {
        .container-vesting {
            padding: 0;

            .content-vest {
                padding: 10px;
            }

            .list-item-vest {
                gap: 10px;
            }

            .title-vest {
                font-size: 18px;
            }

            .dec-vest {
                padding-bottom: 10px;
            }

            .wrap-item-vest {
                width: calc(50% - 10px);
            }

            .item-vest {
                padding: 10px;
                min-height: auto;
                height: 80px;
            }

            .text-unlock {
                font-size: 14px;
            }

            .num-unlock {
                font-size: 24px;
            }

            .action-vest {
                padding: 15px 0;
                gap: 20px;
            }

            .table-vest {
                margin-top: 10px;
            }

            .table-vest {
                overflow-y: auto;

                table {
                    width: 800px;
                }
            }

            .item-vest1 {
                font-size: 18px;
            }
        }
    }

    .title-mobile-respage {
        font-family: Radio Canada;
        font-size: 20px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        color: #fff;
        margin-bottom: 15px;
        text-align: left;
    }
}

.port-wallet {
    background: #2B3342;
    border-radius: 12px;

    .mey-balance-wallet {
        padding: 15px;
        border-top: 1px solid #FFFFFF33;

        .top-mey {
            display: flex;
            align-items: center;
            font-family: Radio Canada;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;

            span {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.2px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #2B79F7;
                margin-left: 5px;
            }

            img {
                width: 20px;
                display: block;
                margin-right: 5px;
            }
        }

        .bottom-mey {
            display: flex;
            align-items: center;
            padding-top: 10px;
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #FFC700;

            img {
                width: 20px;
                display: block;
                margin-right: 5px;
            }
        }
    }
}