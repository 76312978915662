.footer-app {
    padding: 20px 20px 0 20px;
    .row-footer {
        background: #3B3F4566;
        border: 1px solid #3B3F4566;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px;
        border-radius: 16px;
        @media (max-width: 768px) {
            display: unset;
            padding: 0;
        }
        .left-footer {
            width: 40%;
            @media (max-width: 768px) {
                width: unset;
                text-align: center;
            }
            .box-img {
                margin-bottom: 15px;
                img {
                    width: 118px;
                    display: block;
                    @media (max-width: 768px) {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
            .desc-ft {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                color: #fff;
                margin-bottom: 15px;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            .email-ft {
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #FFFFFFA6;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                transition: 0.3s ease;
                &:hover {
                    color: #00EFFF;
                }
                img {
                    width: 16px;
                    display: block;
                    margin-right: 5px;
                }
                @media (max-width: 768px) {
                    justify-content: center;
                }
            }
            .groud-info-token {
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    justify-content: center;
                }
                .row-info {
                    background: #3B3F4566;
                    border: 1px solid #3B3F4533;
                    padding: 7px 15px;
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    margin-right: 15px;
                    cursor: pointer;
                    img {
                        width: 32px;
                        display: block;
                        margin-right: 5px;
                    }
                }
            }
        }
        .right-footer {
            width: 60%;
            display: flex;
            justify-content: space-between;
            @media (max-width: 768px) {
                width: unset;
                flex-wrap: wrap;
                margin-top: 20px;
            }
            .list-menu-ft {
                @media (max-width: 1024px) {
                    width: 40%;
                }
                .title-name {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #FFFFFFA6;
                    margin-bottom: 20px;
                    @media (max-width: 1024px) {
                        flex-wrap: wrap;
                        margin-bottom: 8px;
                        margin-top: 8px;
                        font-size: 16px;
                    }
                }
                .menu-ft {
                    li {
                        a {
                            font-family: Radio Canada;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 19.2px;
                            text-align: left;
                            color: #fff;
                            padding: 10px 0;
                            display: block;
                            transition: 0.3s ease;
                            display: flex;
                            align-items: center;
                            img {
                                width: 18px;
                                display: block;
                                margin-right: 10px;
                            }
                            &:hover {
                                color: #00EFFF;
                            }

                            @media (max-width: 1024px) {
                                font-size: 14px;
                                padding: 8px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}