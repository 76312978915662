.wrap-airdrop {
    .wrap-tabs-air {
        position: relative;
        .row-search {
            position: absolute;
            right: 0;
            width: fit-content;
            z-index: 333;
            input {
                border: 1px solid #3B3F4533;
                width: 300px;
                height: 40px;
                background: #3B3F4566;
                border-radius: 8px;
                padding: 10px 10px 10px 40px;
                font-family: Radio Canada;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.4;
                text-align: left;
                color: #fff;
                &:focus {
                    border: 1px solid #00bbd46a !important;
                }
            }

            img {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .tabs-airdrop {
            margin-top: 40px;
            .ant-tabs-nav {
                &::before {
                    border-bottom: 1px solid transparent;
                }
                .ant-tabs-nav-list {
                    border: 1px solid #29374E;
                    width: 300px;
                    padding: 5px;
                    border-radius: 12px;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                    .ant-tabs-tab {
                        margin: 0 auto;
                        width: calc(4 / 12 * 100%);
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 16.8px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #fff;
                        justify-content: center;
                        border-radius: 8px;
                        &.ant-tabs-tab-active {
                            background: #2B79F7;
                            color: #fff;
                            .ant-tabs-tab-btn {
                                color: #fff;
                                text-align: center;
                            }
                        }
                    }
                }
                .ant-tabs-ink-bar {
                    display: none;
                }
            }
        }
    }
}
.frow-content {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #3B3F45;
    position: relative;
    .status-type {
        position: absolute;
        left: 10px;
        top: 10px;
        font-family: "Radio Canada", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        text-align: left;
        background: #000;
        border-radius: 99px;
        padding: 5px 12px;
        display: flex;
        align-items: center;
        border: 1px solid #000;
        width: fit-content;
        &.progress {
            color: rgb(0, 239, 255);
            border: 1px solid rgb(0, 239, 255);
        }
        &.upcoming {
            color: #2B79F7;
            border: 1px solid #2B79F7;
        }
        &.complete {
            color: #FFAF51;
            border: 1px solid#FFAF51;
        }
    }
    .box-img {
        img {
            display: block;
            height: 230px;
            object-fit: cover;
            width: 100%;
        }
    }
    .frow-box {
        padding: 15px;
        .name-type {
            font-family: Radio Canada;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
            margin-bottom: 15px;
        }
        .list-type-air {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;
                &:last-child {
                    padding-bottom: 0px;
                }
                .txt-l {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.2px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #ffffff89;                    
                }
                .txt-r {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: right;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #fff;   
                    display: flex;
                    align-items: center;  
                    img {
                        display: block;
                        margin-right: 5px;
                        width: 20px;
                    }               
                }
            }
        }
        .gr-button {
            margin-top: 20px;
            .btn-claim {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: center;
                background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box,
                    linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
                border-radius: 50em;
                border: 1px solid transparent;
                box-shadow: 0px 0px 12px 0px #2B79F7 inset;
                padding: 11.5px 23px;
                color: #fff;
                border-radius: 8px;
                cursor: pointer;
                width: 100%;
                &.dis {
                    background: #8E8E8E33;
                    cursor: no-drop;
                    box-shadow: 1px 1px 1px 0px #EFEFEF40 inset;
                }
            }
        }
    }
}
.box-no-data-air {
    padding: 60px 0;
    img {
        width: 200px;
        margin: 0 auto;
        display: block;
    }
}

.pagination-table {
    padding: 10px 0;
    margin-top: 30px;
    text-align: center;
    .ant-pagination-item {
        border: 1px solid #2B79F729;
        a {
            color: #fff;
        }
        &.ant-pagination-item-active {
            background: #2B79F7;
        }
    }
    .ant-pagination-item-link {
        svg {
            fill: #fff;
        }
    }
    .ant-pagination-disabled {
        .ant-pagination-item-link {
            svg {
                fill: #ffffff77 !important;
            }
        } 
    }
}