.main-stake-pool {
    padding-top: 130px;
    background-size: contain !important;
    background-position: top !important;
    padding-bottom: 50px;
}

.banner-stake-pool {
    position: relative;
    margin-top: 100px;
}
.pool-expired {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title .anticon svg {
    display: inline-block;
    color: #075cfc;
    cursor: pointer;
}
.expired-text{
    font-family: 'Radio Canada';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FAC668;
}
.success-content a {
    word-wrap: break-word;
}

.banner-stake-pool .content-banner-pool {
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.banner-stake-pool .content-banner-pool .content-left {
    width: 65%;
}

.banner-stake-pool .content-banner-pool .content-left .title-pool {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 138%;
    /* or 55px */
    color: #1e2329;
    margin-bottom: 8px;
    text-align: left;
}

.banner-stake-pool .content-banner-pool .content-left .desc-pool {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 151.5%;
    /* or 24px */
    color: #787a9b;
}

.banner-stake-pool .content-banner-pool .content-right {
    width: 35%;
}

.banner-stake-pool .content-banner-pool .content-right .total-value {
    background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
    border-radius: 6px;
    color: #fff;
    padding: 20px;
}

.banner-stake-pool .content-banner-pool .content-right .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #707A8A;
}

.banner-stake-pool .content-banner-pool .content-right .item:last-child {
    margin-bottom: 0;
}

.banner-stake-pool .content-banner-pool .content-right .item .text {
    display: block;
    color: #1e2329;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
}

.banner-stake-pool .content-banner-pool .content-right .item .btn-buy-bscs {
    background: transparent;
    padding: 5px 10px;
    border: 1px solid #858585;
    border-radius: 50px;
    display: flex;
    align-items: center;
}

.banner-stake-pool .content-banner-pool .content-right .item .btn-buy-bscs img {
    margin-right: 5px;
}

.banner-stake-pool .content-banner-pool .content-right .item.cus {
    display: block;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    margin: 0 auto !important;
    background: #000;
    border: 1px solid rgba(41, 55, 78, 1);
    border-radius: 12px;
    padding: 5px;
}

.tabs-stake-pool .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
}

.tabs-stake-pool .ant-tabs-top>.ant-tabs-nav:before {
    border-bottom: 0px solid gray;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn {
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: #707A8A !important;
}

.tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover {
    color: #fac668;
}

/* .tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
    display: none;
} */
/* .tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-top
  > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0 !important;
} */
.tabs-stake-pool {
    /* margin-top: 20px; */
    margin-bottom: 40px;
}

.tabs-item-pool {
    margin-top: 30px;
}

.tabs-item-pool .columns {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}

.tabs-item-pool .columns .colum {
    padding: 15px;
    width: calc(3 / 12 * 100%);
}

.tabs-item-pool .columns .colum:nth-child(1) .content-item {
    background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
    border-radius: 6px;
}

.tabs-item-pool .columns .colum:nth-child(3) .content-item {
    background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
    border-radius: 6px;
}

.tabs-item-pool .columns .colum:nth-child(2) .content-item {
    background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
    border-radius: 6px;
}

.tabs-item-pool .columns .colum:nth-child(4) .content-item {
    background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
    border-radius: 6px;
}

.tabs-item-pool .columns .colum:nth-child(2) .content-item .surget-item {
    background: #ead6ff;
    border-radius: 8px;
}

.tabs-item-pool .columns .colum:nth-child(4) .content-item .surget-item {
    background: #ead6ff;
    border-radius: 8px;
}

.tabs-item-pool .columns .colum .content-item .header-item {
    padding: 25px 20px 10px 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tabs-item-pool .columns .colum .content-item .header-item .header-l .name-token {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    color: #1e2329;
}

.tabs-item-pool .columns .colum .content-item .header-item .header-l .days-token {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #707A8A;
    text-align: left;
    margin-top: 5px;
}

.tabs-item-pool .columns .colum .content-item .header-item .header-r .box-img {
    position: relative;
}

.tabs-item-pool .columns .colum .content-item .header-item .header-r .box-img img {
    position: relative;
    z-index: 2;
}

.tabs-item-pool .columns .colum .content-item .header-item .header-r .box-img .img-small {
    position: absolute;
    top: -12px;
    left: -12px;
    z-index: 1;
}

.tabs-item-pool .columns .colum .body-item {
    padding: 20px 20px;
}

.tabs-item-pool .columns .colum .body-item .surget-item .item-txt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.tabs-item-pool .columns .colum .body-item .surget-item .item-txt .txt-l {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #707A8A;
}

.tabs-item-pool .columns .colum .body-item .surget-item .item-txt .txt-r {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 0.01em;

    color: #1e2329;
}

.tabs-item-pool .columns .colum .body-item .button-select .btn-select {
    background: #1e2329;
    box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
    border-radius: 6px;
    height: 46px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;

    color: #ffffff;
    border: transparent;
    width: 100%;
    margin-top: 27px;
}

.banner-stake-pool .content-banner-pool .content-right .item .txt-right {
    margin-right: -10px;
}

.banner-stake-pool .content-banner-pool .content-right .item .main-colors {
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 27px;
    /* identical to box height */

    text-align: left;

    color: #1e2329 !important;
}

.d-block {
    display: block;
}

.tabs-stake-pool .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
}

.tabs-stake-pool .ant-tabs-tab {
    padding: 8px 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #707A8A;
}

.tabs-stake-pool .ant-tabs-tab.ant-tabs-tab-active {
    background: rgba(43, 121, 247, 1);
    border-radius: 12px;

}

.tabs-stake-pool .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
    margin: 0 auto;
}

.tabs-item-pool .columns .colum .body-item .surget-item {
    background: #f8dddd;
    border-radius: 8px;
    padding: 10px 15px;
}

@media screen and (max-width: 768px) {
    .container-full {
        width: 100%;
        padding: 0;
    }

    .banner-stake-pool .box-img img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 10px;
    }

    .banner-stake-pool .content-banner-pool .content-left .title-pool {
        font-size: 18px;
    }

    .banner-stake-pool .content-banner-pool {
        width: 100%;
        right: 0;
        padding: 10px;
        top: 48%;
    }

    .banner-stake-pool .content-banner-pool .content-left {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .banner-stake-pool .content-banner-pool .content-right {
        width: 100%;
    }

    .banner-stake-pool .content-banner-pool .content-left .desc-pool {
        display: flex;
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
    }

    .tabs-item-pool .columns .colum {
        width: 100%;
    }
}

.banner-stake-pool .content-banner-pool .content-right .total-value .item .btn-buy-bscs:hover {
    border: 1.5px solid #24232396 !important;
}

.icon-lp {
    height: 50px;
    left: 12px;
    top: 4px;
}

.banner-stake-pool .box-img {
    position: relative !important;
}

.banner-stake-pool .box-img img {
    position: relative !important;
    z-index: 0 !important;
}

.banner-stake-pool .box-img .img-small-bot {
    position: absolute !important;
    top: 0px !important;
    left: 70px !important;
    z-index: 1 !important;
}

.tooltip-textfee .ant-tooltip-content {
    width: 230px;
}

.tooltip-textfee .ant-tooltip-inner {
    display: flex;
    justify-content: center;
    background: #1e2329;
    /* color: #fff; */
    /* -webkit-background-clip: padding-box !important; */
    -webkit-text-fill-color: #fff !important;
    border-radius: 12px;
}

/* .tooltip-textfee .ant-tooltip-arrow {
  background: #1e2329;
} */
.link-buy-bscs {
    padding-bottom: 10px;
    display: flex;
    color: #1e2329;
}

.link-buy-bscs:hover {
    color: #fdd284 !important;
    cursor: pointer;
}

.img-link {
    margin-right: 5px;
    height: 21px;
    width: 21px;
}




.main-stake-pool-v2 {
    background-position: top !important;
    padding: 20px 20px 50px 20px;
    background-size: contain !important;
}

.item-info {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: calc(75% - 20px);
}

.container-stake {
    font-family: "Radio Canada", sans-serif;
}

.item-1 {
    width: 33%;
    border-radius: 12px;
    display: flex;
    background: rgba(43, 121, 247, 0.1);
    align-items: center;
    gap: 8px;
    padding: 18px 16px;
}

.item-1-logo img {
    width: 43px;
    height: 43px;
    display: block;
}

.big-title {
    color: #ffffffae;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    padding-bottom: 30px;
}

.title-item {
    color: #ffffff9b;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.num-item {
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

}
.num-item span{
     color: #ffffff9b;
}

.item-1-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.num-item.apr {
    color: rgba(8, 92, 252, 1);

}

.tabs-stake-pool.v2 {
    padding-top: 35px;
}

.wrap-all {
    min-height: 550px;
    width: calc(75% - 20px);
    height: auto;
    border-radius: 12px;
    background: rgba(43, 121, 247, 0.1);

}

.stake {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.link-sc {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #2B79F7;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
}

.link-sc:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: 0.2s ease-in-out;
}

.link-sc img {
    width: 16px;
    height: 16px;
    display: block;
}

.action-pool {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    width: calc(25% - 20px);
}

.des-amount ul li {
    list-style-type: disc;
}

.des-amount ul {
    padding-left: 20px;
}

.your-stake {
    padding: 0 20px;
    border-radius: 12px;
    border: 1px solid transparent;
    background: rgba(43, 121, 247, 0.1);
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.logo-reward img {
    width: 40px;
    height: 40px;
}

.wrap-action-rw {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.your-stake.acc {
    border-radius: 6px;
    padding: 0 20px;

    background: rgba(43, 121, 247, 0.1);
    height: 50%;
}

.your-stake.acc:hover {
    border: 1px solid transparent;
   
}
.your-stake:hover {
    border: 1px solid rgba(8, 92, 252, 1);
    transition: 0.3s ease-in-out;
}

.your-stake .title {
    color: #707A8A;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    padding-bottom: 4px;
    text-align: left;

}

.line-status .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: rgba(0, 188, 212, 1) !important;
}

.your-stake .num {
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.your-stake .num span {
    color: #707A8A;
}

.ac-reward-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.action-button {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
    padding-top: 15px;
}

.btn-action {
    width: 100%;
    height: 50px;
}
.btn-action button:hover{
    box-shadow: 0 0 10px rgba(83, 148, 255, 1);
    cursor: pointer;
}
.btn-action button {
    width: 100%;
    height: 100%;
}

.btn-stake-reward.dis {
    border: none;
    border-radius: 8px;
    background: rgba(43, 121, 247, 0.1);

    color: #707A8A;
    text-align: center;
    font-family: Radio Canada;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-withdraw-reward.dis {
    border: none;
    border-radius: 8px;
    background: rgba(43, 121, 247, 0.1);
    color: #707A8A;
    text-align: center;
    font-family: Radio Canada;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-stake-reward {
    border: none;
    border-radius: 8px;
    background: rgba(8, 92, 252, 1);
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-withdraw-reward {
    border: none;
    border-radius: 8px;
    background: rgba(8, 92, 252, 1);
    box-shadow: 1px 1px 1px 0px rgba(239, 239, 239, 0.25) inset;

    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-stake-reward:hover {
    cursor: pointer;
}

.btn-withdraw-reward:hover {
    cursor: pointer;
}

.btn-stake-reward.dis:hover {
    cursor: not-allowed;
}

.btn-withdraw-reward.dis:hover {
    cursor: not-allowed;
}

.stake-your-token {
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 30px;
}

img.img-step-stake {
    width: 30px;
    height: 30px;
    display: block;
}

.content-stake .ant-steps-icon {
    line-height: 0px !important;
}

.content-stake {
    padding: 0px 30px 30px 30px;
}

.content-stake .ant-steps-item-title:after {
    background-color: #AAB6CD !important;
}

/* .content-stake .ant-steps-item-active .ant-steps-item-title:after {
    background-color: #ff9100ed !important;
} */
.content-stake .ant-steps-item-title {
    font-size: 16px;
}

.content-stake .ant-steps-item-active .ant-steps-item-title {
    color: #00BCD4;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;

}

.text-ac {
    color: rgba(0, 188, 212, 1);
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;

}

.line-status .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #707A8A;
}

.line-status .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #707A8A;
}

.line-status .ant-steps-item-title {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    color: #707A8A !important;

}

.content-step1 .title {
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    color: #fff;
    padding-top: 15px;
}

.content-step1 .des {
    padding-top: 8px;
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.21px;
    color: #707A8A
}

.item-step1 {
    display: flex;
    gap: 10px;
    padding-top: 25px;
}

.card-item {
    background: rgba(3, 3, 3, 1);
    padding: 10px 5px 10px 5px;
    width: 25%;
    height: auto;
    border-radius: 12px;
}

.line-status {
    width: calc(75% - 20px);
    padding: 26px 0;
}

.title-card {
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.check-card {
    padding-bottom: 10px;
}

.check-card img {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
}

.check-card.acive img:nth-child(2) {
    display: none;
}

.check-card.acive img:nth-child(1) {
    display: block;
}

.check-card img:nth-child(1) {
    display: none;
}

.content-card {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.5%;
    padding: 10px 20px 10px 20px;
}

.check-rule .text:hover {
    cursor: pointer;
}

.check-real {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.check-real:hover {
    cursor: pointer;
}

/* .check-real span {
    padding-top: 5px;
} */

.steps-action {
    gap: 15px;
    display: flex;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    justify-content: center;
}

.btn-control:hover {
    cursor: pointer;
}

.btn-control img {
    width: 24px;
    height: 24px;
    display: block;
}

.btn-control {
    display: flex;
    width: 40px;
    height: 40px;
    /* padding: 10px 15px; */
    border: none;
    border-radius: 8px;
    background: rgba(43, 121, 247, 1);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    align-items: center;
    justify-content: center;
}

.btn-control.dis {
    width: 40px;
    height: 40px;
    /* padding: 10px 15px; */
    border: none;
    border-radius: 8px;
    background: transparent;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #707A8A;
    text-align: center;
    font-family: "Radio Canada", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    border: 1px solid rgba(43, 121, 247, 1);
}

.btn-control.dis:hover {
    cursor: not-allowed;
}

.check-real span {
    color: rgba(255, 255, 255, 1);
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.terms {
    color: #707A8A;
}

.terms:hover {
    text-decoration: underline;
}

.icon-right-stake {
    width: calc(25% - 20px);
    position: absolute;
    right: 0;
}

.icon-right-stake img {
    width: 100%;
    height: 100%;
}

.wrap-item-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.content-step2 .title {
    color: #fff;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
}

.tabs-stake-pool.v2 .ant-tabs-nav {
    width: 80%;
}

.content-step2 .des {
    padding-top: 5px;
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.content-input {
    width: 70%;
    /* background: #fff; */
    margin: 0 auto;
    border-radius: 6px;
    padding: 30px 30px 20px 30px;
}
span.tec-tem{
   background: rgba(3, 3, 3, 0.6);
   padding: 3px 5px;
   border-radius: 8px;
}
span.tec-time {
    color: rgba(255, 255, 255, 0.6);
    padding-left: 2px;
}
.due-fee-text{
    color: red;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: left;
    padding-bottom: 3px;
}
.due-fee{
    padding-top: 20px;
}
.due-fee-text span{
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}
.amount-stake-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.amount-stake-title.fix{
    padding-bottom: 20px;
}
.title-left span{
    font-size: 16px;
    font-weight: 600px;
    color: #085CFC;
}

.amount-stake-title .title-left {
    color: #fff;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.amount-stake-title .title-right {
    color: #707A8A;
    font-family: 'Radio Canada';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.13px;
}

.amount-stake-title .title-right span {
    color: #fff;
    font-family: 'Radio Canada';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.input-amount {
    position: relative;
}

.input-amount .amount-stake {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 6px;
    background: rgba(3, 3, 3, 1);

    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1) inset;
    font-family: Radio Canada;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.7px;
    text-align: left;

    padding-left: 15px;
    color: #fff !important;
}

.posi {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
}

.btn-max-stake {
    width: 50px;
    height: 36px;
    border: none;
    border-radius: 6px;
    background: #1e2329;
    color: #085CFC;
    font-weight: 600;
    text-align: center;
}

.palce-token {
    color: #616772;
    text-align: right;
    font-family: 'Radio Canada';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
}

.des-amount {
    color: #fff;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 157%;
    padding-top: 15px;
}

.staking-rule {
    text-align: start;
}

.des-amount li {
    text-align: start;
    padding-bottom: 3px;
}

.check-rule {
    text-align: center;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;

}

.check-rule .text {
    color: #fff;
}

.un-fee {
    color: #1E2329;
}

.btn-max-stake:hover {
    cursor: pointer;
}

.content-step3 {
    padding-top: 25px;
}

.content-card.cus {
    color: #fff;
}

.content-card span {
    color: #fff;
}

.content-step3 .title {
    color: #fff;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.content-step3 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 20px;
}

.amount-stake::placeholder {
    color: #707A8A;
}

.pool-empty {
    height: calc(100vh - 129.5px);
    overflow-y: auto;
    padding: 0 20px;
}

.text-empty {
    font-size: 20px;
    padding-top: 80px;
    font-family: 'Radio Canada';
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.autho-content {
    margin: 0 auto;
    background: #030303;
    border-radius: 6px;
    width: 70%;
    padding: 30px 20px 30px 20px;
}

.content-stake.wd {
    padding-top: 80px;
    padding-bottom: 80px;
}

.content-step4 .title {
    padding-top: 45px;
    color: #fff;
    text-align: center;
    font-family: Radio Canada;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
}

.autho-title {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 20px;
}

.autho-please {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.autho-link {
    color: #229ED9;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.autho-progress {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 20px;
}

.autho-progress .ant-progress .ant-progress-inner {
    background-color: #1E2329;
}

.autho-link:hover {
    text-decoration: underline;
}

.autho-progress .ant-progress-text {
    display: none;
}

.content-step4 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.confirm-content {
    padding: 60px 30px;
    background: #030303;
    width: 60%;
    margin: 0 auto;
    border-radius: 8px;
}

.cf-content1 {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.content-step5 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.content-step5 .title {
    padding-top: 25px;
    color: #fff;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.success-content {
    background: #030303;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;
}

.img-success img {
    width: 75px;
    height: 75px;
}

.mid-content1 {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.mid-content2 {
    color: #707A8A;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.mid-content {
    color: #fff;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mid-content3:hover {
    text-decoration: underline;
}

.spin-stake .ant-spin {
    position: relative;
    color: #229ED9;
}

.spin-stake .ant-spin::before {
    content: "";
    border: 4px solid #E4E4EB1A;
    width: 100%;
    height: 100%;
    border-radius: 99px;
    position: absolute;

}

.modal-confirm .ant-modal-header .ant-modal-title {
    color: #fff;
    padding-top: 20px;
    text-align: center;
    font-family: 'Radio Canada';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-text1 {
    color: #707A8A;

    text-align: center;
    font-family: 'Radio Canada';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 20px;
}

button.btn-control.done {
    width: 240px;
}

.spin-stake {
    text-align: center;
    padding-bottom: 5px;
}

.modal-confirm .ant-modal-content {
    background: #070F1B;
    border-radius: 6px;
}

.modal-confirm .ant-modal-header {
    border-radius: 6px;
    background: #070F1B;


}

.modal-confirm .ant-modal-close-x {
    display: none;
}

.modal-confirm .ant-modal-body {
    padding: 10px 0 30px 0;
}

.img-warning {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.unstake-warning {
    padding: 20px 0 60px 0;
    border-radius: 8px;
    background: #030303;
    width: 70%;
    margin: 50px auto 50px auto;
}

.img-warning img {
    width: 100%;
    height: 100%;
}

.content-warning1 {
    padding-top: 20px;
    text-align: left;
    padding-left: 20px;
    font-family: 'Radio Canada';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--waring, linear-gradient(223deg, #FAC668 -1.23%, #DDA134 102.46%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content-warning2 {
    padding-top: 10px;
    text-align: left;
    padding-left: 20px;
    font-family: 'Radio Canada';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--waring, linear-gradient(223deg, #FAC668 -1.23%, #DDA134 102.46%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content-input.unstake {
    width: 70%;
    padding-bottom: 50px;
    margin-top: 15px;
}

.posi-unstake {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
}

.title.title-unstake {
    padding-top: 20px;
}

.boc-time-pto{
    background: #0159F9;
    border-radius: 3px;
    padding: 4px 10px;
}
.boc-time-pto .tec-tem{
    background: transparent;
    color: #fff;
}
.boc-time-pto .tec-time {
    color: rgba(255, 255, 255, 0.5);
    padding-left: 2px;
}

@media (min-width: 1500px) {
    .container-stake {
        max-width: 1800px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
    }
}

input:focus {
    outline: none;
    border: 1px solid rgba(8, 92, 252, 1) !important;
}

.count-down-unstake {
    padding-top: 3px;
}

.time-down {
    color: #fff;
    text-align: left;
    font-family: "Radio Canada", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 991px) {
    .big-title {
        font-size: 32px;
    }

    .item-1 {
        width: 100%;
    }

    .item-info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .container-stake {
        padding: 0 10px;
    }

    .stake {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .wrap-all {
        width: 100%;
        height: 100%;
    }

    .action-pool {
        width: 100%;
        height: 100%;
    }

    .content-stake {
        padding: 15px;
    }

    .item-step1 {
        flex-wrap: wrap;
    }

    .card-item {
        height: 100%;
        width: 100%;
    }

    .action-pool {
        gap: 0;
    }

    .your-stake {
        height: 100%;
        padding: 10px;
        margin-bottom: 15px;
    }

    .your-stake .title {
        margin-bottom: 5px;
    }

    .btn-withdraw-reward {
        font-size: 13px !important;
    }

    .btn-action {
        height: 40px;
        width: 48%;
    }

    .btn-stake-reward {
        font-size: 13px !important;
    }

    .steps-action {
        width: 100%;
    }

    .content-input {
        width: 100%;
        padding: 15px;
    }

    .amount-stake-title {
        display: block;
        justify-content: space-between;
        padding-bottom: 10px;
        text-align: justify;
    }

    .autho-content {
        width: 100%;
        padding: 15px;
    }

    .autho-progress {
        width: 100%;
    }

    .autho-link {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .confirm-content {
        width: 100%;
        padding: 15px;
    }

    .success-content {
        width: 100%;
        padding: 15px;
    }

    .mid-content3 {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .action-button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .unstake-warning {
        width: 100%;
        margin: 10px 0;
        padding: 15px;
    }

    .title.title-unstake {
        padding-top: 0;
    }

    .content-input.unstake {
        width: 100%;
    }

    .tabs-stake-pool .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
        width: 100%;
    }

    .tabs-stake-pool .ant-tabs-tab {
        width: calc(4 / 12 * 100%);
    }

    .main-stake-pool-v2 {
        padding-bottom: 0;
    }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
.item-1{
    padding: 10px;
}
.num-item{
    overflow-wrap: anywhere;
    font-size: 17px;
    text-align: left;
}
.line-status {
    width: 100%;
}
.stake{
    gap:15px;
}
.wrap-all{
    width: 70%;
}
.action-pool{
     width:30%;
}
.card-item{
    min-height: 200px;
    height: auto;
}
.content-card{
    padding: 6px;
    overflow-wrap: break-word;
}
.wrap-item-stake {
    width: calc(6 / 12* 100% - 24px);
}
.card-item {
        min-height: 200px;
        height: auto;
        width: calc(50% - 10px);
    }
.item-step1{
    flex-wrap: wrap;
}
.content-stake{
    padding: 0px 10px 10px 10px;
}
.content-input{
    width: 100%;
}
.your-stake .num{
    text-align: left;
}
.autho-content{
    width: 100%;
}
.confirm-content{
    width: 100%;
}
.success-content{
    width: 100%;
}
.line-status .ant-steps .ant-steps-item-icon{
margin-inline-end: 4px;
}
.line-status .ant-steps-item-title{
    font-size: 13px;
    padding-inline-end:5px
}
.unstake-warning{
    width: 100%;
}
.content-stake.wd {
    padding-top: 20px;
    padding-bottom: 50px;
}
.content-input.unstake{
    width: 100%;
}
.icon-right-stake{
    display: none;
}
.item-info{
    width: 100%;
}
}

@media screen and (max-width: 768px) {
    .container-stake{
        padding: 0;
    }
    .icon-right-stake-mo{
        width: 100%;
        padding: 10px 0;
    }
    .tabs-stake-pool.v2 .ant-tabs-nav{
        align-items: center;
        width: 100%;
    }
    .line-status {
        width: 100%;
        padding: 10px 0;
    }
    .line-status .ant-steps {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: start;
    }
    .line-status .ant-steps-item-icon{
        margin-inline-end: 6px !important;
    }
    .line-status .ant-steps-item-title {
        font-size: 16px;
        font-weight: 500;
    }
    .text-ac{
        font-size: 16px;
        font-weight: 500;
    }
    .your-stake.acc{
        padding: 10px;
    }
    .btn-action {
        height: 40px;
        width: calc(50% - 7px);
    }
    .your-stake{
        padding: 15px 10px;
    }
    .line-mo {
        background: rgba(255, 255, 255, 0.1);
        height: 1px;
        width: 100%;
    }
    /* .wrap-all.expired{
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    } */
    .expired-text{
        font-size: 24px;
    }
    .pool-expired{
        padding: 20px 15px;
    }
    .amount-stake-title .title-left{
        letter-spacing: normal;
        padding-bottom: 8px;
    }
    .content-input.unstake {
    margin-top: 10px;
}
.content-step3 {
    padding-top: 15px;
}
.content-step4 .title{
    padding-top: 10px;
}
.content-input{
    padding: 0px;
}
    .amount-stake-title.fix {
    padding-bottom: 10px;
}
    .title-right{
        padding-bottom: 8px;
    }
    .wrap-all{
        min-height: auto;
    }
    .content-stake.wd {
        padding-top: 10px; 
        padding-bottom: 10px;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .breadcrum-line{
        display: none;
    }
    .item-info{
        flex-direction: column;
        align-items: center;
    }
    .icon-right-stake-mo img{
        width: 60%;
    }
    .icon-right-stake-mo{
        display: flex;
        justify-content: center;
    }
    .item-1{
        width: 70%;
    }
    .tabs-stake-pool.v2 .ant-tabs-nav {
        width: 100%;
    }
    .stake{
        flex-direction: column-reverse;
    }
    .action-pool{
        width: 100%;
    }
    .your-stake.acc{
        padding: 10px 20px;
    }
    .action-button{
        flex-direction: row;
        flex-wrap: nowrap ;
    }
    .btn-action {
        width: 50%;
        height: 50px;
        
    }
    .your-stake{
        padding: 10px 20px;
    }
    .wrap-all{
        width: 100%;
        min-height: auto;
    }
    .pool-expired {
        padding: 20px 15px;
    }
    .wrap-item-info{
        justify-content: center !important;
    }
    .line-status{
        width: 100%;
    }
}